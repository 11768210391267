import React, { useContext } from "react"
import { Chat } from "../reducers/chatsSlice"
import css from "./ChatInfo.module.css"
import { Button, EditableText, H3 } from "@blueprintjs/core"
import useChatInfo from "../hooks/useChatInfo"
import AvatarImage from "./AvatarImage"
import GpsDeviceSelector from "./GpsDeviceSelector"
import { XmppContext } from "../stream/xmppClient"
import { selectDriverParticipant } from "../reducers/chatsSliceSelectors"

import cssUtils from "./utilsCss.module.css"
import { useAppSelector } from "../reducers/hooks"
import { selectChatSubject } from "../reducers/subjectsSlice"
import useGetGpsDevices from "../hooks/useGetGpsDevices"

type ChatInfoProp = {
  chat: Chat
  onClose: () => void
  onUpdateDescription: (description: string) => void
  onUpdateGpsDevice: (device: string | null, driverJID: string) => void
}

const ChatInfo = ({
  chat,
  onClose,
  onUpdateDescription,
  onUpdateGpsDevice,
}: ChatInfoProp) => {
  const [, chatTitle, photoSrc, additionalInfo] = useChatInfo(chat)
  const subject = useAppSelector(selectChatSubject(chat.jid))
  const { client } = useContext(XmppContext)
  const driverObj = useAppSelector(selectDriverParticipant(chat.jid))
  const driver = driverObj ? driverObj.jid : null
  const { gpsDevices, currentGpsDevice, refreshGpsDevices } = useGetGpsDevices(
    client,
    driver,
  )

  return (
    <div className={css.chatInfo}>
      <div className={css.header}>
        <Button minimal icon="cross" onClick={onClose} />
      </div>
      <div className={css.body}>
        <AvatarImage name={chatTitle} src={photoSrc} size="xlarge" />
        <H3>{chatTitle}</H3>
        <div className={cssUtils.dimmedText}>{additionalInfo}</div>
        <div className={css.editableTextWrapper}>
          <EditableText
            className={css.editableText}
            multiline={true}
            placeholder="Add a description..."
            onConfirm={onUpdateDescription}
            defaultValue={subject?.text}
            confirmOnEnterKey={true}
          />
        </div>

        {driver && (
          <GpsDeviceSelector
            currentGpsDeviceValue={currentGpsDevice}
            gpsDevices={gpsDevices}
            onDeviceChange={(deviceValue) => {
              onUpdateGpsDevice(deviceValue, driver)
              refreshGpsDevices()
            }}
          />
        )}
      </div>
    </div>
  )
}

export default ChatInfo
