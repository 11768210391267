import React, { ChangeEvent, useContext, useState } from "react"
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Label,
  TextArea,
} from "@blueprintjs/core"
import { XmppContext } from "../stream/xmppClient"
import AdHocCommands from "../api/adHocCommands"

type WhatsappAccountStatusDialogProps = {
  isOpen: boolean
  status: string | undefined
  onClose: () => void
  onSaveSuccess: (newStatus: string) => void
}

const WhatsappAccountStatusDialog = ({
  isOpen,
  status,
  onClose,
  onSaveSuccess,
}: WhatsappAccountStatusDialogProps) => {
  const [newStatus, setNewStatus] = useState<string | undefined>("")
  const { client } = useContext(XmppContext)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const onOpening = () => {
    if (status !== newStatus) {
      setNewStatus(status)
    }
  }

  const onStatusChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNewStatus(event.target.value)
  }

  const onSave = async () => {
    setIsSaving(true)
    // to remove status from WhatsApp we sent <space> because empty step does not remove it
    const finalStatus = newStatus && newStatus.length > 0 ? newStatus : " "
    await AdHocCommands.updateWhatsappStatus({ client, status: finalStatus })
    setIsSaving(false)
    onSaveSuccess(newStatus || "")
  }

  return (
    <Dialog
      title="WhatsApp account status"
      onOpening={onOpening}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DialogBody>
        <Label>
          This information will be visible to drivers in your WhatsApp profile
          <TextArea
            className="w-full"
            value={newStatus}
            onChange={onStatusChange}
          />
        </Label>
      </DialogBody>
      <DialogFooter
        actions={
          <>
            <Button
              intent="danger"
              text="Cancel"
              large
              minimal
              onClick={onClose}
            />
            <Button
              loading={isSaving}
              intent="primary"
              text="Save"
              onClick={onSave}
              large
              outlined
            />
          </>
        }
      />
    </Dialog>
  )
}

export default WhatsappAccountStatusDialog
