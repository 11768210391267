import { Client } from "@xmpp/client"
import XmppVCardApi from "../api/xmppVCardApi"
import { selectWhatsappRosterItem } from "./rosterSelectors"
import { AppDispatch, RootState } from "./store"

export const getWhatsappStatus = (client: Client) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const whatsappRosterItem = selectWhatsappRosterItem(getState())

    if (!whatsappRosterItem) {
      return undefined
    }

    const vcard = await XmppVCardApi.fetchVCard(client, whatsappRosterItem.jid)

    return vcard?.description
  }
}
