import { Element } from "@xmpp/xml"
import {
  Message,
  MessageDisplayType,
  MessageFallback,
  MessageType,
  Reference,
  Thumbnail,
} from "../reducers/chatsSlice"

type ParseOptions = {
  timestamp?: string
  toBeSent?: boolean
}

class MessageParser {
  static parse(stanza: Element, options?: ParseOptions): Message {
    const timestamp = options?.timestamp
    const toBeSent = options?.toBeSent || false

    if (!stanza.is("message")) {
      throw `Unable to parse non message stanza: ${stanza.toString()}`
    }

    return {
      id: MessageParser.id(stanza, toBeSent),
      originId: MessageParser.originId(stanza),
      to: MessageParser.to(stanza),
      from: MessageParser.from(stanza),
      type: MessageParser.type(stanza),
      body: MessageParser.body(stanza),
      translation: MessageParser.translation(stanza),
      timestamp: timestamp || new Date().toISOString(),
      thumbnail: MessageParser.thumbnail(stanza),
      file: MessageParser.file(stanza),
      url: MessageParser.url(stanza),
      fileName: MessageParser.fileName(stanza),
      forwarded: MessageParser.forwardedMessage(stanza, options),
      references: MessageParser.references(stanza),
      replaceId: MessageParser.replaceId(stanza),
      missedCallNumber: MessageParser.missedCallNumber(stanza),
      replyMessage: MessageParser.replyMessage(stanza),
      markers: [],
      reactions: [],
      displayType: MessageDisplayType.Message,
    }
  }

  static replaceId(stanza: Element): string | undefined {
    const replace = stanza.getChild("replace", "urn:xmpp:message-correct:0")

    if (!replace) return undefined

    return replace.attrs.id
  }

  static references(stanza: Element): Reference[] {
    const references = stanza.getChildren("reference", "urn:xmpp:reference:0")

    if (!references) return []

    // In case of a reply message, stanza body consists of two parts: fallback (body of a message we replied to)
    // and the actual text of the reply. In the UI only the body is displayed,
    // so position 0 of a message on our side is right after the end of the fallback.
    // That's why we need to substract that value (fallback length)
    // from the `begin` and `end` values of the reference to get the correct position in the UI
    const fallbackLength = Number(
      stanza
        .getChild("fallback", "urn:xmpp:fallback:0")
        ?.getChild("body")
        ?.getAttr("end") ?? 0,
    )

    return (
      references
        .map((reference) => {
          return {
            type: reference.attrs.type,
            begin: Number(reference.attrs.begin) - fallbackLength,
            end: Number(reference.attrs.end) - fallbackLength,
          }
        })
        // We don't want to display rederences to the fallback part
        .filter((reference) => reference.begin >= 0)
    )
  }

  static body(stanza: Element) {
    const fallback =
      stanza.getChild("fallback", "urn:xmpp:feature-fallback:0") ||
      stanza.getChild("fallback", "urn:xmpp:fallback:0")
    if (fallback) {
      const end = fallback.getChild("body")?.getAttr("end")
      return stanza.getChildText("body")?.slice(end) ?? ""
    }

    return stanza.getChildText("body") || ""
  }

  static translation(stanza: Element) {
    return stanza.getChildren("body").length > 1
      ? stanza.getChildren("body")[1].getText()
      : undefined
  }

  static to(stanza: Element): string {
    return stanza.attrs.to
  }

  static from(stanza: Element): string {
    return stanza.attrs.from
  }

  static type(stanza: Element): MessageType {
    return stanza.attrs.type || "chat"
  }

  static id(stanza: Element, toBeSent: boolean): string {
    if (MessageParser.type(stanza) === "groupchat") {
      const stanzaId = stanza.getChild("stanza-id")

      if (!stanzaId && !toBeSent)
        throw "Group chat message must have stanza-id included"
      if (stanzaId) return stanzaId.attrs.id
    }

    return stanza.attrs.id
  }

  static originId(stanza: Element): string | undefined {
    const originId = stanza.getChild("origin-id")
    if (originId) return originId.attrs.id

    return undefined
  }

  static file(stanza: Element): boolean {
    return !!MessageParser.url(stanza)
  }

  static url(stanza: Element): string | undefined {
    return (
      stanza
        .getChild("reference", "urn:xmpp:reference:0")
        ?.getChild("media-sharing", "urn:xmpp:sims:1")
        ?.getChild("sources")
        ?.getChild("reference")?.attrs.uri || undefined
    )
  }

  static fileName(stanza: Element): string | undefined {
    return (
      stanza
        .getChild("reference", "urn:xmpp:reference:0")
        ?.getChild("media-sharing", "urn:xmpp:sims:1")
        ?.getChild("file")
        ?.getChildText("name") || undefined
    )
  }

  static thumbnail(stanza: Element): Thumbnail | undefined {
    const thumbnail = stanza
      .getChild("reference", "urn:xmpp:reference:0")
      ?.getChild("media-sharing", "urn:xmpp:sims:1")
      ?.getChild("file")
      ?.getChild("thumbnail")
    if (thumbnail) {
      return {
        width: parseInt(thumbnail.getAttr("width"), 10),
        height: parseInt(thumbnail.getAttr("height"), 10),
        uri: thumbnail.getAttr("uri"),
      }
    }
  }

  static forwardedMessage(
    stanza: Element,
    options?: ParseOptions,
  ): Message | undefined {
    const message = stanza.getChild("forwarded")?.getChild("message")

    if (!message) return undefined

    return MessageParser.parse(message, options)
  }

  static missedCallNumber(stanza: Element): string | undefined {
    return stanza
      .getChild("missed-call", "urn:slickshift:missed-call:0")
      ?.getAttr("tel")
  }

  static replyMessage(stanza: Element): MessageFallback | undefined {
    const reply = stanza.getChild("reply", "urn:xmpp:reply:0")
    const fallback = stanza.getChild("fallback", "urn:xmpp:fallback:0")
    if (!reply || !fallback) return

    const body = (
      stanza
        .getChildText("body")
        ?.slice(0, fallback.getChild("body")?.getAttr("end")) ?? ""
    ).trim()

    const [author, ...rest] = body.split("\n").map((line) => line.slice(2))

    return {
      id: String(reply.getAttr("id")),
      author:
        author.match(/(.*) wrote:/)?.[1] || author.match(/(.*):/)?.[1] || "",
      body: rest.join("\n"),
    }
  }
}

export default MessageParser
