import React from "react"
import { SearchHit } from "../../lib/searchResultParser"
import SearchMessageRowFile from "./SearchMessageRowFile"

export default function SearchMessageBubbleBody({
  messageHit,
  filesClickable,
}: {
  messageHit: SearchHit
  filesClickable: boolean
}) {
  return (
    <>
      {messageHit.document.hasFile && messageHit.document.file && (
        <SearchMessageRowFile
          file={messageHit.document.file}
          nonClickable={!filesClickable}
        />
      )}
      {messageHit.highlight?.body?.value ? (
        <div className="text-sm text-gray-600 dark:text-gray-300">
          <div
            dangerouslySetInnerHTML={{
              __html: messageHit.highlight.body.value
                .replace(
                  /<mark>/g,
                  '<span class="bg-blue-300 dark:bg-blue-700">',
                )
                .replace(/<\/mark>/g, "</span>"),
            }}
          ></div>
        </div>
      ) : (
        <span className="text-sm text-gray-600 dark:text-gray-300">
          {messageHit.document.body}
        </span>
      )}
    </>
  )
}
