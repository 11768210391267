import { Element } from "@xmpp/xml"
import MarkerParser from "./markerParser"
import LocationParser from "./locationParser"
import SettingsParser from "./settingsParser"
import SubjectParser from "./subjectParser"
import RouteParser from "./routeParser"
import ActivityParser from "./activityParser"
import BookmarkEventParser from "./bookmarkParser"
import RetractionParser from "./retractionParser"
import Route2gParser from "./route2gParser"

export type StanzaType =
  | "presence"
  | "message"
  | "chat_marker"
  | "archived_chat_marker"
  | "archived_message"
  | "archived_reaction"
  | "geoloc"
  | "settings"
  | "subject"
  | "retract_destination"
  | "route"
  | "remove_route"
  | "activity"
  | "bookmark_event"
  | "reaction"
  | "message_file_shared"
  | "archived_message_file_shared"
  | "retraction"
  | "archived_retraction"
  | "route2g"
  | "document_signed"
  | "archived_document_signed"

export const parseStanza = (
  stanza: Element,
  jid?: string,
): [Element, StanzaType] | [] => {
  if (stanza.is("presence")) return [enrichTo(stanza, jid), "presence"]

  if (!stanza.is("message")) return []

  if (stanza.getChildText("delay") === "Offline Storage") {
    console.warn("Ignoring stanza from Offline Storage")
    return []
  }

  const archivedMessage = archiveQueryResult(stanza)

  if (archivedMessage && hasRetraction(archivedMessage)) {
    return [enrichTo(archivedMessage, jid), "archived_retraction"]
  }

  if (archivedMessage && hasChatMarker(archivedMessage)) {
    return [enrichTo(archivedMessage, jid), "archived_chat_marker"]
  }

  if (
    archivedMessage &&
    (hasBody(archivedMessage) || isFile(archivedMessage))
  ) {
    return [enrichTo(archivedMessage, jid), "archived_message"]
  }

  if (archivedMessage && hasReactions(archivedMessage)) {
    return [enrichTo(archivedMessage, jid), "archived_reaction"]
  }

  if (hasReactions(stanza)) {
    return [enrichTo(stanza, jid), "reaction"]
  }

  if (hasMessageFileShared(stanza)) {
    return [enrichTo(stanza, jid), "message_file_shared"]
  }

  if (archivedMessage && hasMessageFileShared(archivedMessage)) {
    return [enrichTo(archivedMessage, jid), "archived_message_file_shared"]
  }

  if (hasDocumentSigned(stanza)) {
    return [enrichTo(stanza, jid), "document_signed"]
  }

  if (archivedMessage && hasDocumentSigned(archivedMessage)) {
    return [enrichTo(archivedMessage, jid), "archived_document_signed"]
  }

  if (hasRetraction(stanza)) {
    return [enrichTo(stanza, jid), "retraction"]
  }

  if (hasSubject(stanza)) {
    return [enrichTo(stanza, jid), "subject"]
  }

  if (hasChatMarker(stanza)) {
    return [enrichTo(stanza, jid), "chat_marker"]
  }

  if (hasGeolocation(stanza)) {
    return [enrichTo(stanza), "geoloc"]
  }

  if (hasDestinationRetract(stanza)) {
    return [enrichTo(stanza), "retract_destination"]
  }

  if (hasSettings(stanza)) {
    return [enrichTo(stanza, jid), "settings"]
  }

  if (hasRouteRemove(stanza)) {
    return [enrichTo(stanza, jid), "remove_route"]
  }

  if (hasRoute(stanza)) {
    return [enrichTo(stanza, jid), "route"]
  }

  if (hasActivity(stanza)) {
    return [enrichTo(stanza, jid), "activity"]
  }

  if (hasBody(stanza) || isFile(stanza))
    return [enrichTo(stanza, jid), "message"]

  if (hasBookmarkEvent(stanza)) {
    return [enrichTo(stanza, jid), "bookmark_event"]
  }

  if (hasRoute2g(stanza)) {
    return [enrichTo(stanza, jid), "route2g"]
  }

  return []
}

export const isFile = (stanza: Element) => {
  return (
    stanza.getChildText("url") ||
    stanza.getChildByAttr("xmlns", "urn:xmpp:sims:1", "urn:xmpp:sims:1", true)
  )
}

const hasRetraction = (stanza: Element) => {
  return RetractionParser.parse(stanza) !== undefined
}

const hasRoute = (stanza: Element) => {
  return RouteParser.parse(stanza) !== undefined
}

const hasRoute2g = (stanza: Element) => {
  return Route2gParser.parse(stanza) !== undefined
}

const hasActivity = (stanza: Element) => {
  return ActivityParser.parse(stanza) !== undefined
}

const hasRouteRemove = (stanza: Element) => {
  return RouteParser.shouldRemoveRoute(stanza)
}

const hasDestinationRetract = (stanza: Element) => {
  return LocationParser.retractChild(stanza, "next_destination") !== undefined
}

const hasGeolocation = (stanza: Element) => {
  return (
    LocationParser.geolocChild(stanza, "current") !== undefined ||
    LocationParser.geolocChild(stanza, "next_destination") !== undefined
  )
}

const hasReactions = (stanza: Element) => {
  return stanza.getChild("reactions") !== undefined
}

const hasMessageFileShared = (stanza: Element) => {
  return stanza.getChild("message-file-shared") !== undefined
}

const hasDocumentSigned = (stanza: Element) => {
  return stanza.getChild("document-signed") !== undefined
}

const hasSettings = (stanza: Element) => {
  return SettingsParser.parseSettings(stanza) !== undefined
}

const hasSubject = (stanza: Element) => {
  return SubjectParser.parse(stanza) !== undefined
}

export const hasBody = (stanza: Element) => {
  const body = stanza.getChildText("body")

  return body && body.length > 0
}

const hasChatMarker = (stanza: Element) => {
  return (
    MarkerParser.getChatMarker("received", stanza) ||
    MarkerParser.getChatMarker("displayed", stanza)
  )
}

const hasBookmarkEvent = (stanza: Element) => {
  return BookmarkEventParser.parse(stanza) !== undefined
}

export const archiveQueryResult = (stanza: Element): Element | undefined => {
  return stanza.getChild("result")?.getChild("forwarded")?.getChild("message")
}

export const enrichTo = (stanza: Element, jid?: string): Element => {
  if (stanza.attrs.to || !jid) return stanza

  stanza.attrs.to = jid
  return stanza
}

export const parseArchivedTimestamp = (stanza: Element): string => {
  const delayed = stanza
    .getChild("result")
    ?.getChild("forwarded")
    ?.getChild("delay")

  if (!delayed) return new Date().toISOString()

  return delayed.attrs.stamp
}
