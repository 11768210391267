import { createSelector } from "@reduxjs/toolkit"
import ChatHelpers from "../lib/chatHelpers"
import MessageHelpers from "../lib/messageHelpers"
import {
  Chat,
  GenericMessage,
  Message,
  MessageRetractionsMap,
} from "./chatsSlice"
import { RootState } from "./store"

export const selectDriverParticipant = (chatJid: string | undefined) => {
  return (state: RootState) => {
    const chat = state.chats.find((c) => c.jid === chatJid)
    return chat ? chat.participants.find((p) => p.role === "driver") : undefined
  }
}

export type ChatWithMessages = ChatJid & {
  name: string
  messages: Message[]
}

export type ChatSimplistic = ChatJid & {
  name: string
  active: boolean
  groups: string[]
}

export type ChatJid = {
  jid: string
}

export const selectChatMessagesWithAppliedRetractionsMemo = createSelector(
  [
    (state: RootState, chat: Chat) => chat.messages,
    (state: RootState, chat: Chat) => chat.messageRetractions,
  ],
  (messages: Message[], messageRetractions: MessageRetractionsMap) => {
    return messages.map((message) => {
      let finalMessage = message
      const retraction = messageRetractions[message.id]
      if (retraction) {
        finalMessage = MessageHelpers.markRetracted(message)
      }

      return finalMessage
    })
  },
)

export const selectChatMessagesWithAppliedRetractions = (chat: Chat) => {
  return () => {
    return chat.messages.map((message) => {
      let finalMessage = message
      const retraction = chat.messageRetractions[message.id]
      if (retraction) {
        finalMessage = MessageHelpers.markRetracted(message)
      }

      return finalMessage
    })
  }
}

export const selectChats = (state: RootState) => {
  return state.chats
}

const selectSimpleChatsDep = (state: RootState) => {
  return state.chats.map((chat) => ({
    name: chat.name,
    jid: chat.jid,
    active: chat.active,
    groups: chat.groups,
  }))
}

const chatSimplisticEquality = (
  prev: ChatSimplistic[],
  next: ChatSimplistic[],
): boolean => {
  if (prev.length !== next.length) return false

  for (let i = 0; i < prev.length; i++) {
    if (
      prev[i].jid !== next[i].jid ||
      prev[i].active !== next[i].active ||
      prev[i].name !== next[i].name
    )
      return false
  }

  return true
}

export const selectSimpleChats = createSelector(
  selectSimpleChatsDep,
  (chats) => {
    return chats
  },
  {
    memoizeOptions: {
      equalityCheck: chatSimplisticEquality,
    },
  },
)

export const selectFilteredChatsAndMessages = (jids: string[]) =>
  createSelector([selectChats], (chats) => {
    return chats
      .filter((chat) => jids.includes(chat.jid))
      .map((chat) => ({
        name: chat.name,
        jid: chat.jid,
        messages: chat.messages,
      }))
  })

export const selectFilteredSimpleChats = (jids: string[]) =>
  createSelector(
    [selectChats],
    (chats) => {
      return chats
        .filter((chat) => jids.includes(chat.jid))
        .map((chat) => ({
          name: chat.name,
          jid: chat.jid,
          active: chat.active,
          groups: chat.groups,
        }))
    },
    {
      memoizeOptions: {
        equalityCheck: chatSimplisticEquality,
      },
    },
  )

export const selectRoomChats = createSelector(
  (state: RootState) => state.chats,
  (chats) => {
    return chats.filter((chat) => chat.room)
  },
)

export const selectActiveChatIndex = (state: RootState) => {
  if (state.chats.length === 0) return -1

  const index = selectRoomChats(state).findIndex((chat) => chat.active)
  if (index === -1) return 0

  return index
}

export const selectActiveChatJid = createSelector(
  (state: RootState) => state.chats,
  (chats) => {
    return chats.filter((chat) => chat.room).find((chat) => chat.active)?.jid
  },
)

export const selectMessageChat = (message: GenericMessage, userJid: string) =>
  createSelector(
    (state: RootState) => state.chats,
    (chats) => {
      return ChatHelpers.findMessageChat(message, chats, userJid)
    },
  )

export const selectActiveChat = createSelector(
  (state: RootState) => state.chats,
  (chats) => {
    const jid = chats
      .filter((chat) => chat.room)
      .find((chat) => chat.active)?.jid

    if (!jid) return chats[0]

    return chats.filter((chat) => chat.room).find((chat) => chat.jid === jid)!
  },
)

export const allUnreadCount = (myJid: string | undefined) => {
  return createSelector(
    (state: RootState) => state.chats,
    (chats) => {
      if (!myJid) return 0

      const unreadCounts = chats
        .filter((c) => c.room)
        .map((chat) => ChatHelpers.unreadMessagesCount(chat, myJid))

      return unreadCounts.reduce((acc, curr) => acc + curr, 0)
    },
  )
}

export const chatLatestSentOrReceivedMessage = (chat: Chat | undefined) => {
  return () => {
    if (!chat || chat.messages.length === 0) return undefined
    const candidates: Array<GenericMessage> = []

    candidates.push(chat.messages.slice(-1)[0])

    chat.messages.forEach((message) => {
      const marker = message.markers
        .filter((m) => !MessageHelpers.isSendingOrError(m))
        .sort((a, b) => Date.parse(a.timestamp) - Date.parse(b.timestamp))
        .slice(-1)[0]
      if (marker) {
        candidates.push(marker)
      }

      const reaction = message.reactions
        .filter((m) => !MessageHelpers.isSendingOrError(m))
        .sort((a, b) => Date.parse(a.timestamp) - Date.parse(b.timestamp))
        .slice(-1)[0]

      if (reaction) {
        candidates.push(reaction)
      }
    })

    const lastShare =
      chat?.messageIdsWithFileShared &&
      Object.values(chat.messageIdsWithFileShared)
        .map((share) => share)
        .sort((a, b) => Date.parse(a.timestamp) - Date.parse(b.timestamp))
        .slice(-1)[0]

    if (lastShare) {
      candidates.push(lastShare)
    }

    const lastDocumentSigned =
      chat?.messageIdsWithDocumentSigned &&
      Object.values(chat.messageIdsWithDocumentSigned)
        .map((documentSigned) => documentSigned)
        .sort((a, b) => Date.parse(a.timestamp) - Date.parse(b.timestamp))
        .slice(-1)[0]

    if (lastDocumentSigned) {
      candidates.push(lastDocumentSigned)
    }

    const lastMessageRetraction =
      chat?.messageRetractions &&
      Object.values(chat.messageRetractions)
        .map((retraction) => retraction)
        .sort((a, b) => Date.parse(a.timestamp) - Date.parse(b.timestamp))
        .slice(-1)[0]

    if (lastMessageRetraction) {
      candidates.push(lastMessageRetraction)
    }

    return candidates.sort(
      (a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp),
    )[0]
  }
}

export const latestSentOrReceivedMessageInIM = (state: RootState) => {
  const candidates: Array<GenericMessage> = []
  state.chats
    .filter((chat) => !chat.room)
    .forEach((chat) => {
      const latest = chatLatestSentOrReceivedMessage(chat)()

      if (latest) candidates.push(latest)
    })

  return candidates.sort(
    (a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp),
  )[0]
}

export const hasMessageFileBeenShared = (chat: Chat, messageId: string) => {
  if (!chat.messageIdsWithFileShared) return false

  return !!chat.messageIdsWithFileShared[messageId]
}

export const selectDocumentSigned = (
  chat: Chat,
  messageId: string | undefined,
) => {
  if (
    !messageId ||
    !chat.messageIdsWithDocumentSigned ||
    !chat.messageIdsWithDocumentSigned[messageId]
  )
    return undefined

  return {
    signed: Boolean(chat.messageIdsWithDocumentSigned[messageId]?.signed),
    detectedWithAI: Boolean(
      chat.messageIdsWithDocumentSigned[messageId]?.userJid?.startsWith(
        "wizard",
      ),
    ),
    userJid: chat.messageIdsWithDocumentSigned[messageId]?.userJid,
  }
}

export type CardChat = {
  jid: string
  name: string
  groups: string[]
  draftMessage: string | undefined
  messages: Message[]
  participants: Chat["participants"]
  active: boolean
}

export const selectChatCard = (jid: string) =>
  createSelector([selectChats], (chats) => {
    const chat = chats.find((chat) => chat.jid === jid) as Chat
    if (!chat) return undefined

    return {
      jid: chat.jid,
      name: chat.name,
      groups: chat.groups,
      draftMessage: chat.draftMessage,
      participants: chat.participants,
      messages: chat.messages,
      active: chat.active,
    }
  })

export const selectChat =
  (jid: string) =>
  (state: RootState): Chat | undefined => {
    return state.chats.find((chat) => chat.jid === jid)
  }

export const getCompletenessPayload = (state: RootState) => {
  const MAX_MESSAGES = 50

  const chats = state.chats
  const randomChatIndex = Math.floor(Math.random() * (chats.length - 1))
  const chat = chats[randomChatIndex]
  if (!chat) return null

  const messages = chat.messages.slice(-MAX_MESSAGES) // get last MAX_MESSAGES

  return {
    jid: chat.jid,
    ids: messages.map((m) => m.id),
  }
}
