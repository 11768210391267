import classNames from "classnames"
import React, { memo, useCallback } from "react"
import { isMobile, isMacOs, isWindows } from "../../utils/detectDevice"
import css from "./LazyImage.module.css"
import FileToolbar from "./FileToolbar"
import { Message } from "../../reducers/chatsSlice"
import { updateImageGallery } from "../../reducers/overlaysSlice"
import { useAppDispatch, useAppSelector } from "../../reducers/hooks"
import { mobileRoutes, setMobileRoute } from "../../routes/routes"
import SignedCMRTag from "./SignedCMRTag"
import {
  selectDocumentSigned,
  selectActiveChat,
} from "../../reducers/chatsSliceSelectors"
import useSetSigned from "../../hooks/useSetSigned"

export type LazyImageProps = {
  url: string
  compact?: boolean
  inGroup?: boolean
  message?: Message
  nonClickable?: boolean
  withoutFileToolbar?: boolean
  fromSearch?: boolean
}

const LazyImage = ({
  url,
  compact,
  inGroup,
  message,
  nonClickable,
  withoutFileToolbar = false,
  fromSearch = false,
}: LazyImageProps) => {
  const dispatch = useAppDispatch()
  const activeChat = useAppSelector(selectActiveChat)

  const documentSigned = selectDocumentSigned(activeChat, message?.originId)
  const onMarkAsNotSigned = useSetSigned(message!, false)

  const handleImageClick = useCallback(
    (event: React.MouseEvent) => {
      const isCtrlOrCmdClick =
        event.button === 0 &&
        ((isWindows && event.ctrlKey) || (isMacOs && event.metaKey))
      const isMiddleClick = event.button === 1
      if (isCtrlOrCmdClick || isMiddleClick || fromSearch) {
        window.open(url)
      } else {
        dispatch(
          updateImageGallery({
            visible: true,
            initialImage: url,
          }),
        )
        if (isMobile) {
          setMobileRoute(mobileRoutes.GALLERY)
        }
      }
    },
    [url, dispatch, fromSearch],
  )
  const onMouseDown = !nonClickable ? handleImageClick : undefined
  return (
    <div className="w-full h-full group">
      <div className={classNames(css.imageContainer, "w-full h-full relative")}>
        {!inGroup && message && !withoutFileToolbar && (
          <FileToolbar url={url} message={message} isMobile={isMobile} />
        )}
        <img
          src={url}
          onMouseDown={onMouseDown}
          loading="lazy"
          className={classNames(
            css.image,
            "cursor-pointer",
            !inGroup && "min-w-[275px]",
            !inGroup && "object-scale-down",
            inGroup && "w-28 h-28 object-cover rounded-lg",
            compact && "h-20",
          )}
        />
        {documentSigned?.signed && (
          <SignedCMRTag
            inGroup={inGroup}
            detectedWithAI={documentSigned?.detectedWithAI}
            onMarkAsNotSigned={onMarkAsNotSigned}
          />
        )}
      </div>
    </div>
  )
}

export default memo(LazyImage)
