import { Element } from "@xmpp/xml"

type SearchResult = {
  hits: SearchHit[]
}

export type SearchHit = {
  document: {
    body: string
    host: string
    id: string
    timestamp: number
    chat: string
    chat_jid: string
    sender: string
    hasFile: boolean
    file:
      | {
          url: string
          type: "image" | "file"
        }
      | undefined
  }
  highlight: {
    body: {
      snippet: string
      value: string
    }
    sender?: {
      snippet: string
      value: string
    }
  }
}

const MULTIPLE_BODY_SPLITTER = " - "

export class SearchResultParser {
  static parse(stanza: Element): SearchResult | undefined {
    const jsonString = SearchResultParser.resultJSON(stanza)

    return jsonString
      ? { hits: SearchResultParser.addFileInfo(JSON.parse(jsonString).hits) }
      : undefined
  }

  static addFileInfo(hits: SearchHit[]): SearchHit[] {
    return hits.map((hit) => {
      if (hit.document.body.startsWith("http")) {
        hit.document.hasFile = true
        hit.document.file = {
          url: hit.document.body.split(MULTIPLE_BODY_SPLITTER)[0],
          type: /\.png|\.jpg|\.jpeg|\.webp/i.test(hit.document.body)
            ? "image"
            : "file",
        }
        hit.document.body = hit.document.body.split(MULTIPLE_BODY_SPLITTER)[1]
        if (hit.highlight?.body?.value) {
          hit.highlight.body.value = hit.highlight.body.value.split(
            MULTIPLE_BODY_SPLITTER,
          )[1]
        }
      } else {
        hit.document.hasFile = false
        hit.document.file = undefined
      }

      return hit
    })
  }

  static resultJSON(result: Element): string | undefined {
    const commandElement = result.getChild("command")
    return commandElement?.getChildText("json") ?? undefined
  }
}

export default SearchResultParser
