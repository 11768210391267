import React from "react"

import { useDarkMode } from "../contexts/DarkModeContext"
import { Button, Icon } from "@blueprintjs/core"

export default function DarkModeToggle() {
  const { isDarkMode, toggleDarkMode } = useDarkMode()

  return (
    <Button
      minimal
      icon={<Icon icon={isDarkMode ? "flash" : "moon"} size={18} />}
      onClick={toggleDarkMode}
    />
  )
}
