import React, { useRef, useState, useMemo, useCallback } from "react"
import { PhotoSlider } from "react-photo-view"
import "react-photo-view/dist/react-photo-view.css"
import { ImageTopToolbar } from "./ImageTopToolbar"
import { ImageBottomToolbar } from "./ImageBottomToolbar"
import type { Chat, Message } from "../../reducers/chatsSlice"
import { mapFileUrlToSameOrigin } from "../message/utils/mapFileToSameOrigin"
import Loader from "../Loader"
import { useAppDispatch, useAppSelector } from "../../reducers/hooks"
import {
  hideImageGallery,
  selectImageGallery,
} from "../../reducers/overlaysSlice"
import { isMobile } from "../../utils/detectDevice"
import { mobileRoutes, setMobileRoute } from "../../routes/routes"
import {
  selectActiveChat,
  selectDocumentSigned,
} from "../../reducers/chatsSliceSelectors"

export type ImageGalleryProps = {
  messages: Message[]
}

const isMessageWithImage = (message: Message) => {
  const m = message.forwarded || message
  return (
    !m.retracted &&
    m.file &&
    m.url &&
    (/\.png|\.jpg|\.jpeg/i.test(m.url) || m.thumbnail?.uri)
  )
}

const isDocumentSigned = (message: Message, activeChat: Chat) =>
  Boolean(selectDocumentSigned(activeChat, message.originId)?.signed) ||
  Boolean(selectDocumentSigned(activeChat, message.forwarded?.originId)?.signed)

export default function ImageGallery({ messages }: ImageGalleryProps) {
  const dispatch = useAppDispatch()
  const activeChat = useAppSelector(selectActiveChat)
  const [onlySigned, setOnlySigned] = useState(false)
  const { visible, initialImage } = useAppSelector(selectImageGallery)
  const onClose = useCallback(() => {
    dispatch(hideImageGallery())
    if (isMobile) {
      setMobileRoute(mobileRoutes.CHAT)
    }
  }, [dispatch])
  const onToggleOnlySigned = useCallback(() => {
    setOnlySigned(!onlySigned)
  }, [onlySigned])
  const imgRef = useRef<HTMLElement | null>(null)
  const images = useMemo(
    () =>
      messages
        .filter(isMessageWithImage)
        .filter((message) =>
          onlySigned ? isDocumentSigned(message, activeChat) : true,
        )
        .map((messageOrForwardedMessage: Message) => {
          const message =
            messageOrForwardedMessage.forwarded || messageOrForwardedMessage
          const isInitialImage = message.url === initialImage
          if (isInitialImage) {
            imgRef.current = window.document.querySelector(
              `img[src="${message.url}"]`,
            )
          }
          const url =
            /\.pdf/i.test(message.url!) && message.thumbnail?.uri
              ? message.thumbnail.uri
              : message.url
          return {
            src: mapFileUrlToSameOrigin(url!),
            key: message.id,
            originRef: isInitialImage ? imgRef : undefined,
            message,
          }
        }),
    [onlySigned, activeChat, messages, initialImage],
  )
  const initialIndex = useMemo(() => {
    return initialImage ? images.findIndex((i) => i.src === initialImage) : 0
  }, [initialImage, images])

  const [index, setIndex] = useState(initialIndex)

  // If there is no image, then close the gallery. This could happen if
  // someone has deleted a message when someone else has the gallery open.

  if (images.length === 0) {
    onClose()
    return null
  }
  // If the message with the last image has been deleted,
  // we need to adjust the index to the latest available image.
  const adjustedIndex = index >= images.length ? images.length - 1 : index
  return (
    <PhotoSlider
      /*
       * Overwrite z-index of PhotoView-Portal
       * The default z-index of 2000 causes issues
       * with toast visibility, as toast overlays have a lower z-index.
       * Reducing this z-index ensures toasts appear on top of the open gallery,
       * maintaining proper visibility of important notifications.
       */
      className="!z-30"
      images={images}
      visible={visible}
      onClose={onClose}
      index={adjustedIndex}
      onIndexChange={setIndex}
      loadingElement={<Loader />}
      toolbarRender={(props) => <ImageTopToolbar {...props} />}
      overlayRender={(props) => (
        <ImageBottomToolbar
          {...props}
          onToggleOnlySigned={onToggleOnlySigned}
          onlySigned={onlySigned}
        />
      )}
    />
  )
}
