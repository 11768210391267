import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Button, Tooltip, MenuItem, Popover, Tag } from "@blueprintjs/core"
import { ItemRendererProps, Select } from "@blueprintjs/select"
import { DatePicker3 } from "@blueprintjs/datetime2"

import { useDrag, useDrop } from "react-dnd"
import { ChatHistoryContext } from "../contexts/ChatHistoryContext"

import {
  Trash2,
  Grip,
  TriangleAlert,
  AlarmClock,
  WandSparkles,
} from "lucide-react"
import { Location } from "../reducers/locationsSlice"
import { Action, ACTIONS, RouteStop } from "../reducers/routes2gSlice"
import { useGoogleMaps } from "./GoogleMaps"
import {
  format,
  isToday,
  isTomorrow,
  isYesterday,
  differenceInDays,
  isThisYear,
} from "date-fns"
import { useDarkMode } from "../contexts/DarkModeContext"

const HOVER_OPEN_DELAY = 500
const DISABLED_COLOR = "var(--disabled-color, #5F6B7C)"
const DANGER_COLOR = "var(--danger-color, #CD4246)"
const LATE_COLOR = DANGER_COLOR

export const formatUserFriendlyDate = (date: Date): string => {
  if (isToday(date)) {
    return `Today at ${format(date, "HH:mm")}`
  }

  if (isTomorrow(date)) {
    return `Tomorrow at ${format(date, "HH:mm")}`
  }

  if (isYesterday(date)) {
    return `Yesterday at ${format(date, "HH:mm")}`
  }

  const daysDifference = differenceInDays(date, new Date())

  if (daysDifference > 0 && daysDifference < 7) {
    return format(date, `EEEE 'at' HH:mm`) // e.g., "Friday at 14:30"
  }

  if (isThisYear(date)) {
    return format(date, `MMM d 'at' HH:mm`) // e.g., "Apr 15 at 14:30"
  }

  return format(date, `MMM d, yyyy 'at' HH:mm`) // e.g., "Apr 15, 2024 at 14:30"
}

const formatDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  const hoursStr = hours > 0 ? `${hours}h` : ""
  const minutesStr = minutes > 0 ? `${minutes}m` : ""
  const separator = hours > 0 && minutes > 0 ? " " : ""

  return hoursStr + separator + minutesStr || "seconds"
}

type ScheduleButtonProps = {
  stop: RouteStop
  disabled?: boolean
  onDateUpdate: (newDate: Date | null) => void
}
const ScheduleButton = ({ stop, onDateUpdate }: ScheduleButtonProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setSelectedDate(
      stop.scheduled_arrival_time
        ? new Date(stop.scheduled_arrival_time)
        : null,
    )
  }, [stop])

  const handleDateChange = (newDate: Date | null): void => {
    setSelectedDate(newDate)
  }

  const handleClose = () => {
    onDateUpdate(selectedDate) // is this state always dispatched at this point?
    setIsOpen(false)
    window.analytics.track("ScheduleRouteStop", { id: stop.id })
  }

  const getIconColor = () => {
    if (stop.is_late) {
      return LATE_COLOR
    }

    if (!stop.scheduled_arrival_time) {
      return "#5f6b7c"
    }

    return "#238551"
  }

  const renderTooltipContent = () => {
    return (
      <div>
        {stop.estimated_arrival_time && (
          <div>
            Arrival:{" "}
            {formatUserFriendlyDate(new Date(stop.estimated_arrival_time))}
          </div>
        )}
        {stop.scheduled_arrival_time ? (
          <div>
            Scheduled:{" "}
            {formatUserFriendlyDate(new Date(stop.scheduled_arrival_time))}
          </div>
        ) : (
          <div className="pb-2">Set scheduled arrival time</div>
        )}
      </div>
    )
  }

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      interactionKind="click"
      placement="auto"
      content={
        <DatePicker3
          defaultValue={selectedDate || undefined}
          onChange={handleDateChange}
          timePrecision="minute"
          timePickerProps={{ showArrowButtons: true }}
          showActionsBar={true}
          highlightCurrentDay={true}
        />
      }
      renderTarget={({ isOpen, ...targetProps }) => (
        <Tooltip
          content={renderTooltipContent()}
          hoverOpenDelay={HOVER_OPEN_DELAY}
        >
          <Button
            {...targetProps}
            onClick={() => setIsOpen(!isOpen)}
            minimal={true}
          >
            <AlarmClock size={16} color={getIconColor()} />
          </Button>
        </Tooltip>
      )}
    />
  )
}

const Delay = ({ stop }: { stop: RouteStop }) => {
  if (!stop.scheduled_arrival_time || !stop.estimated_arrival_time) {
    return null
  }

  const differenceInMilliseconds =
    new Date(stop.estimated_arrival_time).getTime() -
    new Date(stop.scheduled_arrival_time).getTime()

  const deltaSeconds = Math.floor(differenceInMilliseconds / 1000)

  if (deltaSeconds <= 0) {
    return null
  }

  const delay = Math.floor(Math.abs(differenceInMilliseconds) / 1000)
  const delayDuration = formatDuration(delay)

  return <span className="font-bold">{delayDuration} late</span>
}

type ActionDisplayProps = {
  stop: RouteStop
  disabled: boolean
  onActionUpdate: (action: string) => void
}

const ActionDisplay = ({
  stop,
  disabled,
  onActionUpdate,
}: ActionDisplayProps) => {
  const getActionByValue = (value: string | undefined): Action => {
    const action = ACTIONS.find(
      (action) => action.value.toLowerCase() === value,
    )
    return action ?? ACTIONS.find((action) => action.value === "other")!
  }

  const [selectedAction, setSelectedAction] = useState<Action>(
    getActionByValue(stop.action),
  )

  useEffect(() => {
    setSelectedAction(getActionByValue(stop.action))
  }, [stop])

  const handleItemSelect = (action: Action) => {
    setSelectedAction(action)
    onActionUpdate(action.value)
  }

  const renderAction = (
    option: Action,
    { handleClick, handleFocus, modifiers }: ItemRendererProps,
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={option.value}
        icon={<option.icon className="mr-2" size={16} />}
        text={option.label}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        selected={selectedAction?.value === option.value}
      />
    )
  }

  const getIconColor = (isDarkMode: boolean = false) => {
    if (disabled) return DISABLED_COLOR

    if (stop.is_late) return LATE_COLOR

    return isDarkMode ? "#ffffff" : "#000000"
  }
  const { isDarkMode } = useDarkMode()
  return (
    <Select<Action>
      items={ACTIONS}
      activeItem={selectedAction}
      itemRenderer={renderAction}
      onItemSelect={handleItemSelect}
      filterable={false}
    >
      <Tooltip
        content={
          !disabled && selectedAction.value === "other"
            ? "Select type"
            : selectedAction.label
        }
        hoverOpenDelay={HOVER_OPEN_DELAY}
      >
        <Button minimal={true} disabled={disabled}>
          <selectedAction.icon size={16} color={getIconColor(isDarkMode)} />
        </Button>
      </Tooltip>
    </Select>
  )
}

export type LocationEditViewProps = {
  location?: Location
  onUpdate: (location: Location) => void
  onAbort: () => void
}

export const LocationEditView = ({
  location,
  onUpdate,
  onAbort,
}: LocationEditViewProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [address, setAddress] = useState(location?.formattedAddress || null)

  const { places } = useGoogleMaps()

  const parseCoordinates = useCallback((input: string): string | null => {
    const regex = /(-?\d+[.,]?\d*)\s*[,\s]+\s*(-?\d+[.,]?\d*)/
    const match = input.match(regex)

    if (match) {
      const latStr = match[1].replace(",", ".")
      const lonStr = match[2].replace(",", ".")

      const lat = parseFloat(latStr)
      const lon = parseFloat(lonStr)

      if (!isNaN(lat) && !isNaN(lon)) {
        return `${latStr}, ${lonStr}`
      }
    }

    return null
  }, [])

  useEffect(() => {
    if (!places) return

    if (inputRef.current) {
      const autocomplete = new places.Autocomplete(inputRef.current)

      const listener = autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace()

        if (place.formatted_address) {
          const location: Location = {
            lat: place.geometry?.location?.lat()?.toString(),
            lon: place.geometry?.location?.lng()?.toString(),
            formattedAddress: place.formatted_address,
          }
          place.address_components?.forEach((component: any) => {
            if (component.types.includes("country")) {
              location.countryCode = component.short_name
            }
            if (component.types.includes("locality")) {
              location.locality = component.long_name
            }
            if (component.types.includes("postal_code")) {
              location.postalCode = component.long_name
            }
          })
          onUpdate(location)
        } else if (place.name && parseCoordinates(place.name)) {
          const coordinates = parseCoordinates(place.name)
          if (coordinates) {
            onUpdate({
              formattedAddress: coordinates,
            })
          }
        } else {
          onAbort()
        }
      })

      return () => listener.remove()
    }
  }, [places, location, onUpdate, onAbort, parseCoordinates])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const autocompleteEl = document.querySelector(".pac-container")
      const target = event.target as HTMLElement
      const isAutocompleteClick =
        target.classList.contains("pac-item") ||
        target.classList.contains("pac-item-query") ||
        target.closest(".pac-item") !== null

      if (
        inputRef.current &&
        !inputRef.current.contains(target) &&
        (!autocompleteEl ||
          (!autocompleteEl.contains(target) && !isAutocompleteClick))
      ) {
        onAbort()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [address, onAbort])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      onAbort()
    }
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value)
  }

  return (
    <input
      type="text"
      value={address || ""}
      onChange={handleAddressChange}
      onKeyDown={handleKeyDown}
      placeholder="Enter address"
      ref={inputRef}
      className="w-full border rounded-md px-2 py-1 text-black dark:text-white dark:bg-gray-700 dark:border-gray-600"
      autoFocus
    />
  )
}

type LocationViewProps = {
  location: Location
  disabled: boolean
  detected: boolean
  sourceMessageId?: string
  onUpdate: (location: Location) => void
}
const LocationView = ({
  location,
  disabled,
  detected,
  sourceMessageId,
  onUpdate,
}: LocationViewProps) => {
  const [isEditing, setIsEditing] = useState(false)

  const [isParentTooltipOpen, setIsParentTooltipOpen] = useState(false)
  const [isChildTooltipOpen, setIsChildTooltipOpen] = useState(false)

  const { jumpAndHighlightMessage } = useContext(ChatHistoryContext)

  const getCoordinates = (location: Location) => {
    if (location.lat && location.lon) {
      const lat = parseFloat(location.lat).toFixed(4)
      const lon = parseFloat(location.lon).toFixed(4)
      return `${lat}, ${lon}`
    }
    return undefined
  }

  const formatAddress = useCallback(() => {
    const coordinates = getCoordinates(location)
    if (location.countryCode && location.locality) {
      return `${location.countryCode}, ${location.postalCode ? location.postalCode : ""} ${location.locality}`
    }

    if (coordinates) {
      return coordinates
    }

    if (location.formattedAddress) {
      return location.formattedAddress
    }

    return "?"
  }, [location])

  useEffect(() => {
    formatAddress()
  }, [location, formatAddress])

  const handleClick = () => {
    if (disabled) return

    setIsEditing(true)
  }

  const formatFullLocationInfo = () => {
    const coordinates = getCoordinates(location)
    if (coordinates || location.formattedAddress) {
      return (
        <div>
          {location.formattedAddress && <div>{location.formattedAddress}</div>}
          {coordinates && <div>{coordinates}</div>}
        </div>
      )
    }
    return "Address"
  }

  const onEditAbort = () => {
    setIsEditing(false)
  }

  const handleParentTooltipInteraction = (nextState: boolean) => {
    if (!isChildTooltipOpen) {
      setIsParentTooltipOpen(nextState)
    }
  }

  const AiDetectedTag = () => (
    <Tag
      minimal
      round
      icon={<WandSparkles size={14} />}
      className="cursor-pointer dark:bg-gray-700 dark:text-gray-200"
    >
      <Tooltip
        hoverOpenDelay={HOVER_OPEN_DELAY}
        content="View source"
        onOpening={() => {
          setIsParentTooltipOpen(false)
          setIsChildTooltipOpen(true)
        }}
        onClosing={() => setIsChildTooltipOpen(false)}
      >
        <a
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation()
            if (sourceMessageId) {
              jumpAndHighlightMessage(sourceMessageId)
            }
          }}
          className="dark:text-gray-200 dark:hover:text-white"
        >
          AI detected
        </a>
      </Tooltip>
    </Tag>
  )

  return (
    <div onClick={handleClick}>
      {isEditing ? (
        <LocationEditView
          location={location}
          onUpdate={onUpdate}
          onAbort={onEditAbort}
        />
      ) : (
        <Tooltip
          content={formatFullLocationInfo()}
          hoverOpenDelay={HOVER_OPEN_DELAY}
          className="w-full"
          isOpen={isParentTooltipOpen}
          onInteraction={handleParentTooltipInteraction}
        >
          <Button
            className="truncate w-full justify-start dark:text-gray-200 dark:hover:bg-gray-700"
            minimal
            disabled={disabled}
          >
            <div className="flex items-center gap-2">
              <div>{formatAddress()}</div>
              {detected && <AiDetectedTag />}
            </div>
          </Button>
        </Tooltip>
      )}
    </div>
  )
}

type RouteStopViewProps = {
  stop: RouteStop
  index: number
  onUpdate: (stop: RouteStop, type: string) => void
  onRemove: (stop: RouteStop) => void
  onMove: (from: number, to: number) => void
  onMoveCompleted: () => void
}

const RouteStopView = ({
  stop,
  index,
  onUpdate,
  onRemove,
  onMove,
  onMoveCompleted,
}: RouteStopViewProps) => {
  const ref = React.useRef<HTMLTableRowElement>(null)
  const ItemType = "ROUTE_STOP"

  const updateLocation = useCallback(
    (location: Location) => {
      const updated = {
        ...stop,
        location,
        msgId: undefined,
        addressUpdated: true,
      }
      onUpdate(updated, "location")
    },
    [stop, onUpdate],
  )

  const getLocalISOString = (date: Date): string => {
    const offset = date.getTimezoneOffset() * 60000
    return new Date(date.getTime() - offset).toISOString().slice(0, -1)
  }

  const updateDate = useCallback(
    (date: Date | null) => {
      const updated = {
        ...stop,
        scheduled_arrival_time: date ? getLocalISOString(date) : undefined,
      }
      onUpdate(updated, "date")
    },
    [stop, onUpdate],
  )

  const updateAction = useCallback(
    (action: string) => {
      const updated = {
        ...stop,
        action: action,
      }
      onUpdate(updated, "action")
    },
    [stop, onUpdate],
  )

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item: { index: number }) {
      if (!ref.current || !stop.is_active) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      onMove(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    canDrag: () => !!stop.is_active,
    end(_, monitor) {
      if (monitor.didDrop()) {
        onMoveCompleted()
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  const Distance = () => {
    if (!stop.proximity_status) return null

    const capitalize = (input: string | undefined): string => {
      if (!input) return ""
      return input.charAt(0).toUpperCase() + input.slice(1)
    }

    const formatDistance = (distance: number) => {
      if (distance >= 1000) {
        // we want spaces. In future we need to support proper locale
        return `${Math.floor(distance / 1000).toLocaleString("fr-FR")} km`
      }

      return `${distance} m`
    }

    const renderDistanceValue = () => {
      if (stop.proximity_status === "arrived") return "Nearby"

      if (stop.proximity_status === "arriving") {
        return capitalize(stop.proximity_status)
      }

      if (!stop.remaining_distance) return "?"

      return formatDistance(stop.remaining_distance)
    }

    const getDistanceIntent = () => {
      if (stop.is_late) return "danger"

      return stop.is_current_target === true ? "success" : "none"
    }

    return (
      <Tooltip
        content={
          stop.remaining_time &&
          stop.remaining_distance &&
          !["missed", "left", "arrived"].includes(stop.proximity_status) ? (
            <div>
              <div>Distance: {formatDistance(stop.remaining_distance)}</div>
              <div>Time: {formatDuration(stop.remaining_time)}</div>
            </div>
          ) : (
            "Status"
          )
        }
        hoverOpenDelay={HOVER_OPEN_DELAY}
      >
        <div>
          <Tag
            intent={getDistanceIntent()}
            icon={
              stop.proximity_status === "missed" ? (
                <TriangleAlert size={12} />
              ) : null
            }
            round
            className="dark:bg-gray-700 dark:text-gray-200"
          >
            {renderDistanceValue()}
          </Tag>
        </div>
      </Tooltip>
    )
  }

  const DeleteButton = () => {
    return (
      <Tooltip content="Remove stop" hoverOpenDelay={0}>
        <Button
          minimal={true}
          onClick={() => onRemove(stop)}
          className="invisible group-hover:visible dark:hover:bg-gray-700"
        >
          <Trash2 size={16} color={DANGER_COLOR} />
        </Button>
      </Tooltip>
    )
  }

  const textColor = useMemo(() => {
    if (!stop.is_active) {
      return "text-[#5F6B7C] dark:text-gray-500"
    }

    if (stop.is_late) {
      return "text-[#CD4246] dark:text-red-400"
    }

    return "text-inherit dark:text-gray-200"
  }, [stop])

  return (
    <tr
      ref={ref}
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
      className={`group ${textColor}`}
    >
      <td>
        <Grip
          color="var(--grip-color, gray)"
          size={18}
          className={`invisible ${
            stop.is_active && "group-hover:visible"
          } cursor-move dark:text-gray-500`}
        />
      </td>
      <td>
        <ActionDisplay
          stop={stop}
          onActionUpdate={updateAction}
          disabled={!stop.is_active}
        />
      </td>
      <td className="w-full">
        <LocationView
          location={stop.location}
          onUpdate={updateLocation}
          disabled={!stop.is_active}
          detected={stop.by?.startsWith("assistant@") || false}
          sourceMessageId={stop.msgId}
        />
      </td>
      <td className="whitespace-nowrap text-right">
        {stop.is_active && <Distance />}
      </td>
      <td>
        {stop.is_active && (
          <ScheduleButton stop={stop} onDateUpdate={updateDate} />
        )}
      </td>
      <td className="whitespace-nowrap text-right">
        <Delay stop={stop} />
      </td>
      <td>
        <DeleteButton />
      </td>
    </tr>
  )
}

export default RouteStopView
