import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react"
import { parse } from "@xmpp/jid"
import css from "./Root.module.css"
import utilsCss from "../components/utilsCss.module.css"
import ChatList from "../components/ChatList"
import ChatInput, { ChatInputTextAreaRef } from "../components/ChatInput"
import { XmppContext } from "../stream/xmppClient"
import ChatHistory, { ChatHistoryHandle } from "../components/ChatHistory"
import { Client } from "@xmpp/client"
import ChatHeader from "../components/ChatHeader"
import { Await, useLoaderData } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../reducers/hooks"
import AdHocCommands from "../api/adHocCommands"
import {
  Message,
  changeActiveChat,
  updateDraftMessage,
} from "../reducers/chatsSlice"
import {
  allUnreadCount,
  selectActiveChat,
} from "../reducers/chatsSliceSelectors"
import MessageHelpers from "../lib/messageHelpers"
import FileUploadApi from "../api/fileUploadApi"
import useIsTabActive from "../hooks/useIsTabActive"
import WebNotificationsPermissionRequest from "../components/WebNotificationsPermissionsRequest"
import {
  markMessageDisplayed,
  sendFile,
  sendMessage,
} from "../reducers/chatsSliceThunks"
import NotificationNewAppVersion from "../components/NotificationNewAppVersion"
import MobilePushNotificationsPermissionsRequest from "../components/MobilePushNotificationsPermissionsRequest"
import usePushNotifications from "../push_notifications/usePushNotifications"
import ChatListHeader from "../components/ChatListHeader"
import Loader from "../components/Loader"
import classNames from "classnames"
import ChatInfo from "../components/ChatInfo"
import XmppGroupChatApi from "../api/xmppGroupChatApi"
import { isMobile, isIOS } from "../utils/detectDevice"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import ProfileInfo from "../components/ProfileInfo"
import { useSelectChatNavigation } from "../hooks/useSelectChatNavigation"
// import { useChatReconnectGuard } from "../hooks/useChatReconnectGuard"
import { measurePerformance } from "../instrumentation/sentry"
import { shallowEqual } from "react-redux"
import {
  hideImageGallery,
  hidePDFViewer,
  hideGlobalSearch,
  hideChatSearch,
  isImageGalleryVisible,
  isPDFViewerVisible,
  isGlobalSearchVisible,
  isChatSearchVisible,
} from "../reducers/overlaysSlice"
import { mobileRoutes, setMobileRoute, updateMobileRoute } from "./routes"

const NoChatsEmptyState = () => (
  <div className={utilsCss.center}>
    <Loader />
  </div>
)

const DocumentTitleBasedOnUnreadMessages = () => {
  const { myJid } = useContext(XmppContext)
  const allUnreadMessagesCount = useAppSelector(
    allUnreadCount(myJid.toString()),
  )

  useEffect(() => {
    if (allUnreadMessagesCount > 0) {
      document.title = `(${allUnreadMessagesCount}) Slickshift`
    } else {
      document.title = `Slickshift`
    }
  }, [allUnreadMessagesCount])

  return null
}

export const RootApp = () => {
  // useChatReconnectGuard()

  const { client, myJid } = useContext(XmppContext)
  const activeChat = useAppSelector(selectActiveChat, shallowEqual)
  const dispatch = useAppDispatch()
  const chatHistoryRef = useRef<ChatHistoryHandle>(null)
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const chatListRef = useRef<HTMLDivElement>(null)
  const chatInputTextAreaRef = useRef<ChatInputTextAreaRef>(null)
  const isTabActive = useIsTabActive()
  const [infoOpened, setInfoOpened] = React.useState<
    "chat" | "profile" | undefined
  >()

  const pdfViewerVisible = useAppSelector(isPDFViewerVisible)
  const imageGalleryVisible = useAppSelector(isImageGalleryVisible)
  const globalSearchVisible = useAppSelector(isGlobalSearchVisible)
  const chatSearchVisible = useAppSelector(isChatSearchVisible)

  const onReturnToChatLists = useCallback(
    (
      usingBackButton: boolean = false,
      options: { forceBackToChats: boolean } = { forceBackToChats: false },
    ) => {
      if (isMobile) {
        if (options.forceBackToChats) {
          chatContainerRef.current?.classList.remove(css.active)
          chatListRef.current?.classList.remove(css.hidden)
          updateMobileRoute(mobileRoutes.CHAT_LIST)
          return
        }

        if (pdfViewerVisible) {
          dispatch(hidePDFViewer())
          updateMobileRoute(mobileRoutes.CHAT)
        } else if (imageGalleryVisible) {
          dispatch(hideImageGallery())
          updateMobileRoute(mobileRoutes.CHAT)
        } else if (globalSearchVisible) {
          dispatch(hideGlobalSearch())
          updateMobileRoute(mobileRoutes.CHAT_LIST)
        } else if (chatSearchVisible) {
          dispatch(hideChatSearch())
          updateMobileRoute(mobileRoutes.CHAT)
        } else {
          if (isIOS && usingBackButton) {
            chatContainerRef.current?.classList.remove(css.withTransition)
            chatListRef.current?.classList.remove(css.withTransition)
          }
          chatContainerRef.current?.classList.remove(css.active)
          chatListRef.current?.classList.remove(css.hidden)
          updateMobileRoute(mobileRoutes.CHAT_LIST)
          if (isIOS && usingBackButton) {
            setTimeout(() => {
              chatContainerRef.current?.classList.add(css.withTransition)
              chatListRef.current?.classList.add(css.withTransition)
            }, 350)
          }
        }
      } else {
        chatContainerRef.current?.classList.remove(css.active)
        chatListRef.current?.classList.remove(css.hidden)
      }
    },
    [
      pdfViewerVisible,
      imageGalleryVisible,
      globalSearchVisible,
      chatSearchVisible,
      dispatch,
    ],
  )

  useLayoutEffect(() => {
    if (activeChat && !activeChat.active) {
      dispatch(changeActiveChat({ activeChatJid: activeChat.jid }))
    }
  }, [activeChat, dispatch])

  const { navigateToChat } = useSelectChatNavigation(onReturnToChatLists)

  const dispatchDraftAndActiveChatChange = useCallback(
    (chatJid: string) => {
      if (
        chatInputTextAreaRef.current &&
        activeChat.draftMessage !== chatInputTextAreaRef.current.value()
      ) {
        dispatch(
          updateDraftMessage({
            chatJid: activeChat.jid,
            draftMessage: chatInputTextAreaRef.current.value(),
          }),
        )
      }

      navigateToChat()
      dispatch(changeActiveChat({ activeChatJid: chatJid }))
      window.analytics.track("ChatOpen", { chatJid })
      if (!isMobile) {
        chatInputTextAreaRef.current?.focus()
      }
    },
    [navigateToChat, dispatch, activeChat?.draftMessage, activeChat?.jid],
  )

  const handleOnActiveChatChange = useCallback(
    (chatJid: string) => {
      const interaction = measurePerformance("ChatChange", {
        jid: chatJid,
      })

      dispatchDraftAndActiveChatChange(chatJid)

      chatContainerRef.current?.classList.add(css.active)
      chatListRef.current?.classList.add(css.hidden)
      interaction.end()
    },
    [dispatchDraftAndActiveChatChange],
  )

  const handleOnActiveChatChangeFromNotification = useCallback(
    (chatJid: string) => {
      if (activeChat?.jid === chatJid) {
        chatContainerRef.current?.classList.add(css.active)
        chatListRef.current?.classList.add(css.hidden)
        return
      }

      onReturnToChatLists(false, { forceBackToChats: true })
      dispatchDraftAndActiveChatChange(chatJid)

      setTimeout(() => {
        chatContainerRef.current?.classList.add(css.active)
        chatListRef.current?.classList.add(css.hidden)
      }, 500)
    },
    [dispatchDraftAndActiveChatChange, onReturnToChatLists, activeChat?.jid],
  )

  const onPushNotificationClick = useCallback(
    (toJid: string) => {
      if (!isIOS) return
      handleOnActiveChatChangeFromNotification(toJid)
    },
    [handleOnActiveChatChangeFromNotification],
  )

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.triggerChatChange =
      handleOnActiveChatChangeFromNotification
  }
  window.triggerChatChange = handleOnActiveChatChangeFromNotification

  usePushNotifications(onPushNotificationClick)

  const markMessageDisplayedIfNeeded = useCallback(
    (message: Message) => {
      if (!isTabActive) return

      if (
        !MessageHelpers.isToMyself(message) &&
        !MessageHelpers.isDisplayed(message, myJid.toString()) &&
        MessageHelpers.isIncoming(message, myJid) &&
        (message.type === "chat" || message.type === "groupchat")
      ) {
        dispatch(markMessageDisplayed(client, message))
      }
    },
    [client, dispatch, isTabActive, myJid],
  )

  const handleOnSend = async (text: string, files: FileList) => {
    if (activeChat.draftMessage) {
      dispatch(
        updateDraftMessage({ chatJid: activeChat.jid, draftMessage: "" }),
      )
    }

    await uploadAndSendMultipleFiles(files)

    if (!text.match(/^\s*$/)) {
      // only send non-blank messages
      dispatch(sendMessage(text, client, myJid.toString(), activeChat))
    }

    chatHistoryRef.current?.scrollToBottom()
  }

  const uploadAndSendMultipleFiles = async (files: FileList) => {
    const uploadPromises = Array.from(files).map(async (file) => {
      try {
        const urls = await FileUploadApi.requestSlot(
          client,
          file.name,
          file.size.toString(),
          file.type,
        )
        await uploadAndSendFile(file, urls)
        return { success: true, fileName: file.name }
      } catch (e) {
        console.error(e)
        return { success: false, fileName: file.name }
      }
    })

    const results = await Promise.all(uploadPromises)
    results.forEach((result) => {
      if (!result.success) {
        window.appToaster.show({
          message: `Sorry, file "${result.fileName}" could not be uploaded`,
          intent: "danger",
          timeout: 3000,
        })
      }
    })
  }

  const uploadAndSendFile = async (file: File, urls: [string, string]) => {
    const [get, put] = urls
    await fetch(put, {
      method: "PUT",
      body: file,
    })
    await dispatch(sendFile(file, get, client, myJid.toString()))
  }

  const handleOnToggleChatInfo = (value: "chat" | "profile") => () =>
    setInfoOpened(infoOpened === value ? undefined : value)

  const handleOnUpdateDescription = (description: string) => {
    XmppGroupChatApi.changeSubject(client, activeChat.jid, description).catch(
      () => {
        window.appToaster.show({
          message: "Sorry, but we could not update description",
          intent: "danger",
          timeout: 20,
        })
      },
    )
  }

  const handleSetDriverGPSDevice = (
    device: string | null,
    driverJID: string,
  ) => {
    AdHocCommands.setDriverGPSDevice(client, driverJID, device).catch(() => {
      window.appToaster.show({
        message: "Sorry, but we could not update GPS device",
        intent: "danger",
        timeout: 20,
      })
    })
  }

  return (
    <div className={css.mainContainer}>
      {!activeChat && (
        <>
          <NoChatsEmptyState />
        </>
      )}
      {activeChat && (
        <>
          <NotificationNewAppVersion />
          <DocumentTitleBasedOnUnreadMessages />
          {!isMobile && <WebNotificationsPermissionRequest />}
          {isMobile && <MobilePushNotificationsPermissionsRequest />}
          <div
            className={classNames(css.chatListContainer, css.withTransition)}
            ref={chatListRef}
          >
            <ChatListHeader
              onOpenChatInfo={handleOnToggleChatInfo("profile")}
              onActiveChatChange={handleOnActiveChatChange}
            />
            <div className={css.chatList}>
              <ChatList onActiveChatChange={handleOnActiveChatChange} />
            </div>
          </div>
          <div
            className={classNames(css.chatContainer, css.withTransition, {
              [css.small]: infoOpened,
            })}
            ref={chatContainerRef}
          >
            <ChatHeader
              chat={activeChat}
              onReturnToChatLists={onReturnToChatLists}
              onOpenChatInfo={handleOnToggleChatInfo("chat")}
            />
            <DndProvider backend={HTML5Backend}>
              <ChatHistory
                chat={activeChat}
                chatHistoryRef={chatHistoryRef}
                chatInputRef={chatInputTextAreaRef}
                onFileDropped={(files: FileList) => handleOnSend("", files)}
                markMessageDisplayed={markMessageDisplayedIfNeeded}
              />
            </DndProvider>
            <div className={css.chatInput}>
              <ChatInput
                key={activeChat.jid}
                onSend={handleOnSend}
                chat={activeChat}
                ref={chatInputTextAreaRef}
              />
            </div>
          </div>
          <div
            className={classNames(css.chatInfoContainer, {
              [utilsCss.hidden]: !infoOpened,
              [utilsCss.fullscreen]: isMobile,
            })}
          >
            {infoOpened === "chat" && (
              <ChatInfo
                key={activeChat.jid}
                chat={activeChat}
                onClose={handleOnToggleChatInfo("chat")}
                onUpdateDescription={handleOnUpdateDescription}
                onUpdateGpsDevice={handleSetDriverGPSDevice}
              />
            )}
            {infoOpened === "profile" && (
              <ProfileInfo onClose={handleOnToggleChatInfo("profile")} />
            )}
          </div>
        </>
      )}
    </div>
  )
}

const WithMyJid = ({ client, myJid }: { client: Client; myJid: string }) => {
  const providerValue = useMemo(
    () => ({ client, myJid: parse(myJid) }),
    [client, myJid],
  )

  return (
    <XmppContext.Provider value={providerValue}>
      <RootApp />
    </XmppContext.Provider>
  )
}

const Root = () => {
  const data = useLoaderData() as { myJid: string }
  useEffect(() => {
    if (isMobile) {
      setMobileRoute(mobileRoutes.CHAT_LIST)
    }
  }, [])
  return (
    <React.Suspense fallback={<Loader className={utilsCss.center} />}>
      <Await resolve={data.myJid}>
        {(myJid) => <WithMyJid client={window.XMPP!} myJid={myJid} />}
      </Await>
    </React.Suspense>
  )
}

export default Root
