import React, { useCallback, useContext, useState } from "react"
import { Button, Overlay2 } from "@blueprintjs/core"
import { XmppContext } from "../../stream/xmppClient"
import AdHocCommands from "../../api/adHocCommands"
import SearchResultParser, { SearchHit } from "../../lib/searchResultParser"
import SearchMessageDetails from "./SearchMessageDetails"
import SearchMessageRow from "./SearchMessageRow"
import SearchPopoverHeader from "./SearchPopoverHeader"
import SearchPopoverBar from "./SearchPopoverBar"
import classNames from "classnames"

export default function SearchPopover({
  isOpen,
  onClose,
  chatJid,
}: {
  isOpen: boolean
  onClose: () => void
  chatJid?: string
}) {
  const { client, myJid } = useContext(XmppContext)
  const [hits, setHits] = React.useState<SearchHit[]>([])
  const [searchQuery, setSearchQuery] = React.useState<string>("")
  const [detailsShownFor, setDetailsShownFor] = React.useState<
    SearchHit | undefined
  >(undefined)
  const [
    scrollBeforeDisplayingMessageDetails,
    setScrollBeforeDisplayingMessageDetails,
  ] = useState<number | undefined>(undefined)

  const scrollRef = React.useRef<HTMLDivElement>(null)

  const search = useCallback(async () => {
    window.analytics.track("SearchDone", {
      userJid: myJid.toString(),
      query: searchQuery,
      chatJid: chatJid,
    })
    const response = await AdHocCommands.search({
      client,
      query: searchQuery,
      chatJid: chatJid || "*",
    })

    const hits = SearchResultParser.parse(response)?.hits || []
    setHits(hits)
  }, [client, searchQuery, myJid, chatJid])

  const onMessageClicked = (hit: SearchHit) => {
    window.analytics.track("SearchMessageClicked", {
      userJid: myJid.toString(),
      messageId: hit.document.id,
    })

    setScrollBeforeDisplayingMessageDetails(scrollRef.current?.scrollTop)
    setDetailsShownFor(hit)
  }

  const onGoToAllResults = () => {
    setDetailsShownFor(undefined)
    if (scrollBeforeDisplayingMessageDetails !== undefined) {
      scrollRef.current?.scrollTo(0, scrollBeforeDisplayingMessageDetails)
    }
  }
  return (
    <Overlay2
      onClose={onClose}
      autoFocus
      canEscapeKeyClose
      canOutsideClickClose
      enforceFocus
      hasBackdrop
      isOpen={isOpen}
      usePortal
    >
      <div
        id="default-modal"
        tabIndex={-1}
        aria-hidden="true"
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[100] justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div className="relative w-full h-full">
          <div
            className="relative bg-white dark:bg-gray-900 rounded-lg shadow h-full overflow-auto flex flex-col"
            ref={scrollRef}
          >
            <div className="sticky top-0 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-900 z-10">
              <SearchPopoverHeader
                onClose={onClose}
                clickedMessageHit={detailsShownFor}
              />
              <div className="p-4 md:p-5 space-y-4">
                {detailsShownFor ? (
                  <Button
                    onClick={onGoToAllResults}
                    icon="arrow-left"
                    className="rounded-full h-8"
                  >
                    Go back to results
                  </Button>
                ) : (
                  <SearchPopoverBar
                    onSearch={search}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                )}
              </div>
            </div>
            <div
              className={classNames(
                "flex flex-col p-3 md:p-5 gap-y-4 items-center grow",
                !detailsShownFor && "bg-gray-100 dark:bg-gray-800",
              )}
            >
              {!detailsShownFor &&
                hits.map((hit) => (
                  <SearchMessageRow
                    key={hit.document.id}
                    messageHit={hit}
                    onGoToDetails={() => onMessageClicked(hit)}
                  />
                ))}
              {detailsShownFor && (
                <SearchMessageDetails clickedMessageHit={detailsShownFor} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Overlay2>
  )
}
