import { Client, xml } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import iqMeasure from "../instrumentation/iqMeasure"
import SettingsParser from "../lib/settingsParser"
import { Route2g } from "../reducers/routes2gSlice"
import { extractVhostFromMuc, extractVhostFromUser } from "../jidUtils"

const PUSHNOT_ADDRESS = "pushnot.global"
const INFO_ADDRESS = "info.global"
const WIZARD = "wizard"

export type PushToken =
  | {
      data: string
      type: string
      supportsBackground?: boolean
    }
  | PushSubscription

type GetRoomsItem = {
  jid: string
  name: string
  groups: string[]
}

const AdHocCommands = {
  sendPushNotificationsSubscription: (
    client: Client,
    subscription: PushToken,
  ): Promise<Element> => {
    const { iqCaller } = client

    const measuredIqCaller = iqMeasure(iqCaller, "set_subscription")
    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: PUSHNOT_ADDRESS,
        },
        xml(
          "command",
          {
            node: "set_subscription",
            action: "execute",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml("json", "urn:xmpp:json:0", JSON.stringify(subscription)),
        ),
      ),
    )
  },

  getPushNotificationsSubscription: async (
    client: Client,
  ): Promise<PushToken | undefined> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "get_subscription")

    const result = await measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: PUSHNOT_ADDRESS,
        },
        xml("command", {
          node: "get_subscription",
          action: "execute",
          xmlns: "http://jabber.org/protocol/commands",
        }),
      ),
    )
    return SettingsParser.parsePushSubscription(result)
  },

  setDriverDestination: (
    client: Client,
    to: string,
    address: string,
  ): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "set_next_destination")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: to,
        },
        xml(
          "command",
          {
            node: "set_next_destination",
            action: "execute",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "form" },
            xml(
              "field",
              { var: "address", type: "text-single" },
              xml("value", {}, address),
            ),
          ),
        ),
      ),
    )
  },

  removeDriverDestination: (client: Client, to: string): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "clear_next_destination")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: to,
        },
        xml("command", {
          node: "clear_next_destination",
          action: "execute",
          xmlns: "http://jabber.org/protocol/commands",
        }),
      ),
    )
  },

  upsertRoute2g: (
    client: Client,
    to: string,
    route: Route2g,
  ): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "upsert_route_stops")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: to,
        },
        xml(
          "command",
          {
            node: "upsert_route_stops",
            action: "execute",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "form" },
            xml(
              "reported",
              {},
              xml("field", { var: "address", type: "text-single" }),
              xml("field", { var: "address-updated", type: "boolean" }),
              xml("field", { var: "id", type: "text-single" }),
              xml("field", { var: "msg-id", type: "text-single" }),
              xml("field", { var: "action", type: "text-single" }),
              xml("field", { var: "ref", type: "text-single" }),
              xml("field", {
                var: "scheduled_arrival_time",
                type: "text-single",
              }),
            ),
            ...route.stops.map((stop) =>
              xml(
                "item",
                {},
                xml(
                  "field",
                  { var: "id", type: "text-single" },
                  xml("value", {}, stop.id || ""),
                ),
                xml(
                  "field",
                  { var: "address", type: "text-single" },
                  xml("value", {}, stop.location.formattedAddress || ""),
                ),
                xml(
                  "field",
                  { var: "address-updated", type: "boolean" },
                  xml(
                    "value",
                    {},
                    stop.addressUpdated === true ? "true" : "false",
                  ),
                ),
                xml(
                  "field",
                  { var: "msg-id", type: "text-single" },
                  xml("value", {}, stop.msgId || ""),
                ),
                xml(
                  "field",
                  { var: "action", type: "text-single" },
                  xml("value", {}, stop.action || ""),
                ),
                xml(
                  "field",
                  { var: "ref", type: "text-single" },
                  xml("value", {}, stop.ref || ""),
                ),
                xml(
                  "field",
                  { var: "scheduled_arrival_time", type: "text-single" },
                  xml("value", {}, stop.scheduled_arrival_time || ""),
                ),
              ),
            ),
          ),
        ),
      ),
    )
  },

  getCommandsList: (client: Client, to: string): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "disco_info_commands")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "get",
          to: to,
        },
        xml("query", {
          node: "http://jabber.org/protocol/commands",
          xmlns: "http://jabber.org/protocol/disco#items",
        }),
      ),
    )
  },
  getGPSDeviceList: async (client: Client, to: string): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "get_gps_device")
    const vhost = extractVhostFromUser(to)
    const targetApi = `wizard@${vhost}/gps`

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          type: "set",
          to: targetApi,
        },
        xml(
          "command",
          {
            node: "get_gps_device",
            action: "execute",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "form" },
            xml(
              "field",
              { var: "jid", type: "jid-single" },
              xml("value", {}, to),
            ),
          ),
        ),
      ),
    )
  },
  setDriverGPSDevice: (
    client: Client,
    to: string,
    device: string | null,
  ): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "set_gps_device")
    const vhost = extractVhostFromUser(to)
    const targetApi = `wizard@${vhost}/gps`

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: targetApi,
        },
        xml(
          "command",
          {
            node: "set_gps_device",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "submit" },
            device !== null
              ? xml("field", { var: "device_id" }, xml("value", {}, device))
              : xml("field", { var: "device_id" }, xml("value", {})),
            xml("field", { var: "jid" }, xml("value", {}, to)),
          ),
        ),
      ),
    )
  },

  getRooms: async (client: Client): Promise<GetRoomsItem[]> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "get_rooms")

    const response = await measuredIqCaller.request(
      xml(
        "iq",
        {
          type: "set",
          to: INFO_ADDRESS,
        },
        xml("command", {
          node: "get_rooms",
          action: "execute",
          xmlns: "http://jabber.org/protocol/commands",
        }),
      ),
      4 * 1000,
    )
    const items = response
      ?.getChild("command", "http://jabber.org/protocol/commands")
      ?.getChild("query", "http://jabber.org/protocol/disco#items")
      ?.getChildren("item") as Element[]

    return items.map((item) => {
      const groups = item.getChildren("group").map((g) => g.text())
      return { ...item.attrs, groups } as GetRoomsItem
    })
  },

  shareFile: async ({
    client,
    fileUrl,
    userJid,
    messageId,
    messageRoomJid,
    triggeringRoomJid,
  }: {
    client: Client
    fileUrl: string
    userJid: string
    messageId: string
    messageRoomJid: string | undefined
    triggeringRoomJid: string
  }): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "share_file")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: INFO_ADDRESS,
        },
        xml(
          "command",
          {
            node: "share_file",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "form" },
            xml(
              "field",
              { var: "file_url", type: "text-single" },
              xml("value", {}, fileUrl),
            ),
            xml(
              "field",
              { var: "message_id", type: "text-single" },
              xml("value", {}, messageId),
            ),
            xml(
              "field",
              { var: "user_jid", type: "text-single" },
              xml("value", {}, userJid),
            ),
            xml(
              "field",
              { var: "message_room_jid", type: "text-single" },
              xml("value", {}, messageRoomJid || ""),
            ),
            xml(
              "field",
              { var: "triggering_room_jid", type: "text-single" },
              xml("value", {}, triggeringRoomJid),
            ),
          ),
        ),
      ),
    )
  },
  search: async ({
    client,
    query,
    chatJid,
  }: {
    client: Client
    query: string
    chatJid: string
  }): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "search")

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: INFO_ADDRESS,
        },
        xml(
          "command",
          {
            node: "search",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "form" },
            xml(
              "field",
              { var: "query", type: "text-single" },
              xml("value", {}, query),
            ),
            xml(
              "field",
              { var: "chat_jid", type: "text-single" },
              xml("value", {}, chatJid),
            ),
          ),
        ),
      ),
    )
  },
  getMessageContextFromSearch: async ({
    client,
    timestamp,
    chatJid,
  }: {
    client: Client
    timestamp: string
    chatJid: string
  }): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(
      iqCaller,
      "get_message_context_from_search",
    )

    return measuredIqCaller.request(
      xml(
        "iq",
        {
          from: client.jid,
          type: "set",
          to: INFO_ADDRESS,
        },
        xml(
          "command",
          {
            node: "get_message_context_from_search",
            xmlns: "http://jabber.org/protocol/commands",
          },
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "form" },
            xml(
              "field",
              { var: "timestamp", type: "text-single" },
              xml("value", {}, timestamp),
            ),
            xml(
              "field",
              { var: "chat_jid", type: "text-single" },
              xml("value", {}, chatJid),
            ),
          ),
        ),
      ),
    )
  },

  updateWhatsappStatus: ({
    client,
    status,
  }: {
    client: Client
    status: string
  }): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "set_document_signed")
    const stanza = xml(
      "iq",
      {
        from: client.jid,
        type: "set",
        to: `${WIZARD}@${client.jid!.domain}/wizard`,
      },
      xml(
        "command",
        {
          node: "update_whatsapp_status",
          action: "execute",
          xmlns: "http://jabber.org/protocol/commands",
        },
        xml(
          "x",
          { xmlns: "jabber:x:data", type: "form" },
          xml(
            "field",
            { var: "status", type: "text-single" },
            xml("value", {}, status),
          ),
        ),
      ),
    )
    return measuredIqCaller.request(stanza)
  },
  setDocumentSigned: ({
    client,
    messageId,
    signed,
    messageRoomJid,
    triggeringRoomJid,
  }: {
    client: Client
    messageId: string
    signed: boolean
    messageRoomJid: string
    triggeringRoomJid: string
  }): Promise<Element> => {
    const { iqCaller } = client
    const measuredIqCaller = iqMeasure(iqCaller, "set_document_signed")
    const vhost = extractVhostFromMuc(triggeringRoomJid)
    const stanza = xml(
      "iq",
      {
        from: client.jid,
        type: "set",
        to: `${WIZARD}@${vhost}/wizard`,
      },
      xml(
        "command",
        {
          node: "set_document_signed",
          action: "execute",
          xmlns: "http://jabber.org/protocol/commands",
        },
        xml(
          "x",
          { xmlns: "jabber:x:data", type: "form" },
          xml(
            "field",
            { var: "message_id", type: "text-single" },
            xml("value", {}, messageId),
          ),
          xml(
            "field",
            { var: "signed", type: "boolean" },
            xml("value", {}, signed ? "true" : "false"),
          ),
          xml(
            "field",
            { var: "message_room_jid", type: "jid-single" },
            xml("value", {}, messageRoomJid || ""),
          ),
          xml(
            "field",
            { var: "triggering_room_jid", type: "jid-single" },
            xml("value", {}, triggeringRoomJid),
          ),
        ),
      ),
    )
    return measuredIqCaller.request(stanza)
  },
}

export default AdHocCommands
