import { getCompletenessPayload } from "../reducers/chatsSliceSelectors"
import posthog from "posthog-js"
import { Location } from "../reducers/locationsSlice"

const INTERVAL = 60 * 60
const GRACE_PERIOD = 10

let worker: number | null = null

const report = () => {
  if (!posthog.isFeatureEnabled("completeness-reporting")) {
    console.debug("Completeness skipped")
    return
  }

  console.debug(`Completeness run: ${worker}`)

  const payload = getCompletenessPayload(window.store.getState())
  if (payload) {
    window.analytics.track("CompletenessReport", payload)
  }
  console.debug("CompletenessReport", payload)
}

export const runReporting = () => {
  worker = window.setInterval(report, 1000 * INTERVAL)
  report()
}

let lastLocationReportedAt =
  performance.now() - 1000 * (INTERVAL - GRACE_PERIOD)

export const reportLocation = (driverJid: string, location: Location) => {
  if (lastLocationReportedAt + 1000 * INTERVAL > performance.now()) {
    console.debug("LocationReport Skip")
    return
  }

  const payload = {
    jid: driverJid,
    location,
  }
  console.debug("LocationReport", payload)
  window.analytics.track("LocationReport", payload)
  lastLocationReportedAt = performance.now()
}
