import { useCallback, useContext } from "react"
import { XmppContext } from "../stream/xmppClient"
import { useAppSelector } from "../reducers/hooks"
import {
  selectDocumentSigned,
  selectActiveChat,
  selectMessageChat,
} from "../reducers/chatsSliceSelectors"
import AdHocCommands from "../api/adHocCommands"
import { Message } from "../reducers/chatsSlice"
import DocumentSignedResultParser from "../lib/documentSignedResultParser"
import posthog from "posthog-js"

const useSetSigned = (message: Message, signed: boolean) => {
  if (!message) return undefined
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { myJid, client } = useContext(XmppContext)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const activeChat = useAppSelector(selectActiveChat)
  const isForwarded = !!message.forwarded
  const processedMessage = isForwarded
    ? message.forwarded ?? ({} as Message)
    : message
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const messageChat = useAppSelector(
    selectMessageChat(processedMessage, myJid.toString()),
  )
  const messageId = processedMessage?.originId

  const documentSigned = selectDocumentSigned(activeChat, messageId)

  const isSigned = Boolean(documentSigned?.signed)

  const url = isForwarded ? message.forwarded?.url : message.url
  const shouldShowSignedActions =
    posthog.isFeatureEnabled("signed-cmr") &&
    url &&
    /\.png|\.jpg|\.jpeg|\.pdf/i.test(url) &&
    isSigned != signed
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const callback = useCallback(async () => {
    const messageRoomJid = messageChat?.jid
    const triggeringRoomJid = activeChat.jid
    const userJid = myJid.toString()

    if (messageId && messageRoomJid) {
      try {
        const response = await AdHocCommands.setDocumentSigned({
          client,
          messageId,
          signed,
          messageRoomJid,
          triggeringRoomJid,
        })
        const documentSignedResult = DocumentSignedResultParser.parse(response)
        if (!documentSignedResult?.completed) {
          window.appToaster.show({
            message: `Sorry, but we could not mark this document as ${signed ? "signed" : "not signed"}. Please try again later.`,
            intent: "danger",
          })
        } else {
          window.analytics.track("DocumentSignedChange", {
            messageId,
            url,
            currentDocumentSigned: documentSigned,
            newDocumentSigned: {
              signed,
              userJid,
            },
          })
        }
      } catch (error) {
        console.log(error)
        window.appToaster.show({
          message: `Sorry, but we could not mark this document as ${signed ? "signed" : "not signed"}. Please try again later.`,
          intent: "danger",
        })
      }
    }
  }, [
    documentSigned,
    url,
    messageId,
    signed,
    client,
    myJid,
    activeChat,
    messageChat,
  ])

  return shouldShowSignedActions ? callback : undefined
}

export default useSetSigned
