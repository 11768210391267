import { Client, xml } from "@xmpp/client"
import { Element } from "@xmpp/xml"
import { JID } from "@xmpp/jid"
import { parseArchivedTimestamp, parseStanza } from "../lib/stanzaParser"
import type { AppDispatch, AppStore } from "../reducers/store"
import MessageParser from "../lib/messageParser"
import MarkerParser from "../lib/markerParser"
import {
  addBatchDocumentsSigned,
  addBatchMessageFileShares,
  addBatchMessageReactions,
  addMessageRetraction,
  addOrUpdateParticipant,
  buildChat,
  markMessage,
  updateChatName,
} from "../reducers/chatsSlice"
import { upsertLocation } from "../reducers/locationsSlice"
import LocationParser from "../lib/locationParser"
import { updateConnectionStatus } from "../reducers/connectionStatusSlice"
import {
  joinChat,
  leaveChat,
  processIncomingMessage,
} from "../reducers/chatsSliceThunks"
import { IncomingContext } from "@xmpp/middleware"
import { setUpdateAvailable } from "../reducers/appVersionSlice"
import SettingsParser from "../lib/settingsParser"
import { updateSettings } from "../reducers/settingsSlice"
import SubjectParser from "../lib/subjectParser"
import { upsertChatSubject } from "../reducers/subjectsSlice"
import RouteParser from "../lib/routeParser"
import { removeRoute, upsertRoute } from "../reducers/routesSlice"
import ActivityParser from "../lib/activityParser"
import { upsertActivity } from "../reducers/activitySlice"
import BookmarkEventParser from "../lib/bookmarkParser"
import PresenceParser from "../lib/presenceParser"
import XmppAnalytics from "./xmppAnalytics"
import ReactionsParser from "../lib/reactionsParser"
import MessageFileSharedParser from "../lib/messageFileSharedParser"
import RetractionParser from "../lib/retractionParser"
import ReconnectStatusReactor from "../lib/reconnectStatusReactor"
import { captureError } from "../ErrorHandlers"
import { storeMyJid } from "../lib/cookieStoredJidStorage"
import XmppGroupChatApi from "../api/xmppGroupChatApi"
import { driverJid } from "../jidUtils"
import XmppVCardApi from "../api/xmppVCardApi"
import { upsertProfile } from "../reducers/profilesSlice"
import { upsertRoute2g } from "../reducers/routes2gSlice"
import Route2gParser from "../lib/route2gParser"
import DocumentSignedParser from "../lib/documentSignedParser"

const XmppEventHandlers = {
  handleOnError: (error: Error) => {
    captureError(error, { origin: "XMPPErrorCallback" })
  },

  handleOnStanza: (store: AppStore, xmpp: Client) => {
    const { dispatch } = store

    return (stanza: Element) => {
      if (stanza.attrs.type === "error") {
        captureError(new Error("Received error stanza"), {
          origin: "XMPPErrorStanza",
          extra: { stanza: stanza.toString() },
        })
        return
      }

      const [messageStanza, type] = parseStanza(
        stanza,
        xmpp.jid?.bare().toString(),
      )

      if (!messageStanza || !type) {
        return
      }

      if (!xmpp.jid) return

      if (type === "message") {
        const message = MessageParser.parse(messageStanza, {
          timestamp: parseArchivedTimestamp(stanza),
        })

        dispatch(
          processIncomingMessage(
            { message, myJid: xmpp.jid.toString() },
            xmpp,
            type,
          ),
        )

        return
      }

      if (type === "presence") {
        const presence = PresenceParser.parse(stanza)
        if (!presence) return

        if (presence.selfPresence) {
          if (
            presence.type === "disconnected" &&
            // Presence stanzas related to leaving a chat are in 300+ range
            // https://xmpp.org/extensions/xep-0045.html#registrar-statuscodes-init
            presence.statuses.some((status) => status >= 300)
          ) {
            XmppAnalytics.trackOnChatDisconnected({
              userJid: xmpp.jid.toString(),
              chatJid: presence.from.toString(),
              statuses: presence.statuses,
            })
          }

          // dispatch(
          //   updateChatConnectedStatus({
          //     jid: presence.chat,
          //     status: presence.type,
          //   }),
          // )
        }
      }

      if (type === "chat_marker") {
        const marker = MarkerParser.parse(messageStanza, {
          timestamp: parseArchivedTimestamp(stanza),
        })
        dispatch(markMessage({ marker, myJid: xmpp.jid.toString() }))
      }

      if (type === "retract_destination") {
        const node = LocationParser.retractChild(
          messageStanza,
          "next_destination",
        )

        if (node) {
          dispatch(
            upsertLocation({
              jid: messageStanza.attrs.from,
              itemId: "next_destination",
              value: undefined,
            }),
          )
        }
      }

      if (type === "route") {
        const route = RouteParser.parse(messageStanza)
        if (route) {
          dispatch(upsertRoute({ route }))
        }
      }

      if (type === "route2g") {
        const route = Route2gParser.parse(messageStanza)
        if (route) {
          dispatch(upsertRoute2g({ route: route }))
        }
      }

      if (type === "activity") {
        const activity = ActivityParser.parse(messageStanza)
        if (activity) {
          dispatch(upsertActivity({ activity }))
        }
      }

      if (type === "remove_route") {
        dispatch(removeRoute({ jid: stanza.attrs.from }))
      }

      if (type === "geoloc") {
        const location = LocationParser.parse(messageStanza, "current")

        if (location) {
          dispatch(
            upsertLocation({
              jid: messageStanza.attrs.from,
              itemId: "current",
              value: location,
            }),
          )
        }

        const nextDestination = LocationParser.parse(
          messageStanza,
          "next_destination",
        )

        if (nextDestination) {
          dispatch(
            upsertLocation({
              jid: messageStanza.attrs.from,
              itemId: "next_destination",
              value: nextDestination,
            }),
          )
        }
      }

      if (type === "settings") {
        const settings = SettingsParser.parseSettings(messageStanza)
        if (settings !== undefined) {
          dispatch(updateSettings({ settings }))
        }
      }

      if (type === "subject") {
        const subject = SubjectParser.parse(messageStanza)
        if (subject) {
          dispatch(upsertChatSubject({ subject }))
        }
      }

      if (type === "bookmark_event") {
        const event = BookmarkEventParser.parse(stanza)
        if (!event) return

        if (event.type === "create") {
          dispatch(
            joinChat(xmpp, store, {
              jid: event.bookmark.jid,
              name: event.bookmark.name || event.bookmark.jid,
              room: true,
            }),
          )
        } else if (event.type === "retract") {
          dispatch(
            leaveChat(
              xmpp,
              store,
              buildChat({
                jid: event.jid,
              }),
            ),
          )
        }
      }

      if (type === "reaction") {
        const reaction = ReactionsParser.parse(
          stanza,
          parseArchivedTimestamp(stanza),
        )
        if (!reaction) return

        dispatch(
          addBatchMessageReactions({
            reactions: [reaction],
            myJid: xmpp.jid.toString(),
          }),
        )
      }

      if (type === "message_file_shared") {
        const messageFileShared = MessageFileSharedParser.parse(
          stanza,
          parseArchivedTimestamp(stanza),
        )
        if (!messageFileShared) return

        dispatch(
          addBatchMessageFileShares({ messageFileShares: [messageFileShared] }),
        )
      }

      if (type === "document_signed") {
        const documentSigned = DocumentSignedParser.parse(
          stanza,
          parseArchivedTimestamp(stanza),
        )
        if (!documentSigned) return

        dispatch(addBatchDocumentsSigned({ documentsSigned: [documentSigned] }))
      }

      if (type === "retraction") {
        const retraction = RetractionParser.parse(
          stanza,
          parseArchivedTimestamp(stanza),
        )

        if (!retraction) return

        dispatch(
          addMessageRetraction({
            retraction: retraction,
            myJid: xmpp.jid.toString(),
          }),
        )
      }
    }
  },

  handleOnOnline: async (xmpp: Client, store: AppStore, jid: JID) => {
    store.dispatch(updateConnectionStatus({ status: "online" }))
    storeMyJid(jid.toString())
    window.myJID = jid
    window.appToaster.clear()
  },

  handleOnOffline: (dispatch: AppDispatch) => {
    return () => {
      dispatch(updateConnectionStatus({ status: "offline" }))
    }
  },

  handleOnDisconnect: (dispatch: AppDispatch) => {
    return () => {
      dispatch(updateConnectionStatus({ status: "disconnected" }))
    }
  },

  handleConnectionStatusChange: (client: Client) => {
    return (status: string) => {
      // Check for jid on XMPP so that we are not showing toaster when user trying to login and entered wrong password
      if (client?.jid && status === "disconnect") {
        window.appToaster.show(
          { message: "Lost connection", intent: "danger", timeout: 0 },
          "connection",
        )
      }
    }
  },

  handleReconnectStatusChange: (status: string) => {
    ReconnectStatusReactor.handleReconnectStatusChange(status)
  },

  handleNewClientVersionCommand: (dispatch: AppDispatch) => {
    return (context: IncomingContext<Client>) => {
      if (
        context.stanza.getChild("command")?.attrs.node === "new_client_version"
      ) {
        dispatch(setUpdateAvailable({ updateAvailable: true }))
        return xml("command", {
          xmlns: "http://jabber.org/protocol/commands",
          node: "new_client_version",
          status: "completed",
        })
      }
    }
  },

  handleRoomConfigChange: async (
    client: Client,
    roomJid: string,
    dispatch: AppDispatch,
  ) => {
    const roomConfig = await XmppGroupChatApi.fetchRoomConfig(client, roomJid)
    if (roomConfig && roomConfig.name) {
      dispatch(updateChatName({ chatJid: roomJid, name: roomConfig?.name }))
    }

    const jid = driverJid(roomJid)
    const vcard = await XmppVCardApi.fetchVCard(client, jid)
    if (vcard) {
      dispatch(upsertProfile({ profile: vcard }))
      dispatch(addOrUpdateParticipant({ chatJid: roomJid, participant: vcard }))
    }
  },
}

export default XmppEventHandlers
