import React from "react"
import { Tag } from "@blueprintjs/core"
import { WandSparkles, X } from "lucide-react"
import posthog from "posthog-js"

const SignedCMRTag = ({
  inGroup = false,
  inGallery = false,
  detectedWithAI,
  onMarkAsNotSigned,
}: {
  inGroup?: boolean
  inGallery?: boolean
  detectedWithAI: boolean
  onMarkAsNotSigned?: () => void
}) => {
  return posthog.isFeatureEnabled("signed-cmr") ? (
    <Tag
      round
      intent="primary"
      large={!inGroup}
      className={
        inGallery
          ? "relative !bg-online"
          : "absolute bottom-2 left-2 !bg-online"
      }
    >
      <div className="group flex flex-row gap-x-1">
        {onMarkAsNotSigned && (
          <X
            color="black"
            className="group-hover:block hidden h-4 mt-0.5 cursor-pointer"
            size={14}
            onClick={onMarkAsNotSigned}
          />
        )}
        {!inGroup && detectedWithAI && (
          <WandSparkles color="black" className="h-4 mt-0.5" size={14} />
        )}
        {!inGroup && <span className="text-black">Signed</span>}
        <img
          className={inGroup ? "h-3 mt-0.5" : "h-4 mt-0.5"}
          src="/signed.svg"
        />
      </div>
    </Tag>
  ) : null
}

export default SignedCMRTag
