import { Element } from "@xmpp/xml"

type DocumentSignedResult = {
  completed: boolean
}

export class DocumentSignedResultParser {
  static parse(stanza: Element): DocumentSignedResult | undefined {
    const commandElement = stanza.getChild("command")
    const status = commandElement?.getAttr("status")
    return {
      completed: Boolean(status && status === "completed"),
    }
  }
}

export default DocumentSignedResultParser
