import { Icon, Menu, MenuItem, Popover } from "@blueprintjs/core"
import classNames from "classnames"
import React, {
  PropsWithChildren,
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react"
import { XmppContext } from "../stream/xmppClient"
import { Reaction } from "../reducers/chatsSlice"
import { jid } from "@xmpp/client"
import EmojiIcon from "./icons/EmojiIcon"
import { useAppSelector } from "../reducers/hooks"
import { selectActiveChat } from "../reducers/chatsSliceSelectors"
import posthog from "posthog-js"

const EMOJIS = ["😢", "😮", "😂", "❤️", "👍"]

type MessageBubbleToolbarProps = PropsWithChildren<{
  onReplyClick: () => void
  currentReactions: Reaction[]
  onReactionSelect: (reaction: string | null) => void
  onDelete?: () => void
  onMarkAsSigned?: () => void
  onMarkAsNotSigned?: () => void
  className?: string
}>

const MessageBubbleToolbar = forwardRef<
  { openEmojiPicker: () => void; closeEmojiPicker: () => void },
  MessageBubbleToolbarProps
>(
  (
    {
      onReplyClick,
      currentReactions,
      onReactionSelect,
      className,
      onDelete,
      onMarkAsSigned,
      onMarkAsNotSigned,
    },
    ref,
  ) => {
    const { myJid } = useContext(XmppContext)
    const activeChat = useAppSelector(selectActiveChat)

    const [emojiPickerVisible, setEmojiPickerVisible] = useState(false)

    useImperativeHandle(
      ref,
      () => ({
        openEmojiPicker: () => {
          navigator.vibrate?.(10)
          setEmojiPickerVisible(true)
          window.analytics.track("OpenReactionEmojiPicker", {
            chatJid: activeChat.jid,
          })
        },
        closeEmojiPicker: () => setEmojiPickerVisible(false),
      }),
      [activeChat.jid],
    )
    const isSignedCRMEnabled = posthog.isFeatureEnabled("signed-cmr")
    const showMoreMenu = Boolean(
      onDelete || (isSignedCRMEnabled && (onMarkAsSigned || onMarkAsNotSigned)),
    )

    return (
      <div
        className={classNames(
          "absolute z-10 -right-4 select-none hidden -top-2 space-x-[-1px] lg:inline-flex",
          emojiPickerVisible && "!inline-flex",
          !emojiPickerVisible && "invisible group-hover:visible",
          className,
        )}
      >
        {emojiPickerVisible ? (
          EMOJIS.map((emoji) => {
            const isSelected = currentReactions.some((r) => {
              return (
                myJid.local === jid.parse(r.from).resource &&
                r.emojis.includes(emoji)
              )
            })

            return (
              <MessageBubbleToolbarItem
                key={emoji}
                className={classNames(
                  isSelected && "!bg-[#E8E7F9] dark:!bg-indigo-900",
                )}
                onClick={() => {
                  navigator.vibrate?.(10)
                  onReactionSelect(isSelected ? null : emoji)
                  setEmojiPickerVisible(false)
                }}
              >
                {emoji}
              </MessageBubbleToolbarItem>
            )
          })
        ) : (
          <>
            <MessageBubbleToolbarItem onClick={onReplyClick}>
              <Icon icon="undo" />
            </MessageBubbleToolbarItem>
            <MessageBubbleToolbarItem
              className="p-2 xl:p-1"
              onClick={() => setEmojiPickerVisible(true)}
            >
              <EmojiIcon />
            </MessageBubbleToolbarItem>
            {showMoreMenu && (
              <MessageBubbleToolbarItem onClick={() => {}}>
                <Popover
                  interactionKind="click"
                  placement="top"
                  content={
                    <Menu className="dark:bg-gray-800">
                      {onDelete && (
                        <MenuItem
                          text="Delete message"
                          intent="danger"
                          onClick={onDelete}
                          className="dark:text-white dark:hover:bg-gray-700"
                        />
                      )}
                      {onMarkAsNotSigned && (
                        <MenuItem
                          text="Remove signed mark"
                          onClick={onMarkAsNotSigned}
                          className="dark:text-white dark:hover:bg-gray-700"
                        />
                      )}
                      {onMarkAsSigned && (
                        <MenuItem
                          text="Mark CMR as signed"
                          onClick={onMarkAsSigned}
                          className="dark:text-white dark:hover:bg-gray-700"
                        />
                      )}
                    </Menu>
                  }
                >
                  <Icon icon="more" />
                </Popover>
              </MessageBubbleToolbarItem>
            )}
          </>
        )}
      </div>
    )
  },
)

type MessageBubbleToolbarItemProps = PropsWithChildren<{
  onClick: () => void
  className?: string
}>

const MessageBubbleToolbarItem = ({
  onClick,
  className,
  children,
}: MessageBubbleToolbarItemProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        className,
        "flex items-center justify-center size-9 text-xl xl:size-7 xl:text-base bg-white dark:bg-gray-800 dark:text-white border border-gray-300 dark:border-gray-600 cursor-pointer first:rounded-l-md last:rounded-r-md hover:bg-gray-200 dark:hover:bg-gray-700",
      )}
    >
      {children}
    </div>
  )
}

export default MessageBubbleToolbar
