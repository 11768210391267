import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "./store"

const DRIVER_GROUPS = ["drivers", "shared drivers"]

const selectRoster = (state: RootState) => {
  return state.roster
}

export const selectWhatsappRosterItem = createSelector(
  [selectRoster],
  (roster) => roster.items.find((item) => item.jid.startsWith("+48")),
)

export const driversBasedOnRoster = createSelector([selectRoster], (roster) => {
  return roster.items.filter((item) => {
    return item.groups.some((group) =>
      DRIVER_GROUPS.includes(group.toLowerCase()),
    )
  })
})
