import { Element } from "@xmpp/xml"
import { MessageDisplayType, DocumentSigned } from "../reducers/chatsSlice"
import MessageParser from "./messageParser"

export default class DocumentSignedParser {
  static parse(
    stanza: Element,
    timestamp?: string,
  ): DocumentSigned | undefined {
    const documentSignedNode = stanza.getChild("document-signed")

    if (!documentSignedNode) return

    return {
      id: MessageParser.id(stanza, false),
      to: stanza.attrs.to,
      from: stanza.attrs.from,
      messageId: documentSignedNode.attrs["message_id"],
      signed: documentSignedNode.attrs["signed"] === "true",
      forwarded: documentSignedNode.attrs["forwarded"] === "true",
      userJid: documentSignedNode.attrs["user_jid"],
      timestamp: timestamp || new Date().toISOString(),
      displayType: MessageDisplayType.DocumentSigned,
      type: stanza.attrs.type,
    } as DocumentSigned
  }
}
