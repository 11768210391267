import {
  SessionEnrichmentPlugin,
  AnalyticsEvent,
} from "./SessionEnrichmentPlugin"

export {}

if (process.env.NODE_ENV === "production") {
  const sessionEnrichmentPlugin = new SessionEnrichmentPlugin()

  // @ts-ignore
  window.analytics.addSourceMiddleware(sessionEnrichmentPlugin.handleEvent)
  if (!window.ReactNativeWebView) {
    window.analytics.track(AnalyticsEvent.SessionStarted)
  }
  window.analytics.track("AppLoaded")
}
