import { parse as jidParse } from "./lib/cachedJid"

export const driverJid = (chatJid: string) => {
  const jid = jidParse(chatJid)
  const vhost = extractVhostFromMuc(chatJid)

  return `${jid.getLocal()}@${vhost}`
}

export const userJid = (chatJid: string) => {
  const jid = jidParse(chatJid)
  const vhost = extractVhostFromMuc(chatJid)

  return `${jid.getResource()}@${vhost}`
}

export const extractVhostFromMuc = (chatJid: string) => {
  const jid = jidParse(chatJid)
  const parts = jid.getDomain().split(".").slice(1)
  return parts.join(".")
}

export const extractVhostFromUser = (userJid: string) => {
  const jid = jidParse(userJid)
  return jid.getDomain()
}
