import React, { useCallback, useContext, useEffect, useState } from "react"
import { XmppContext } from "../stream/xmppClient"
import classNames from "classnames"
import css from "./ChatListHeader.module.css"
import JoinChatPopover from "./JoinChatPopover"
import {
  Button,
  Icon,
  Menu,
  MenuItem,
  OverlaysProvider,
  Popover,
  Tooltip,
} from "@blueprintjs/core"
import SearchPopover from "./search/SearchPopover"
import RoomLoading from "./RoomLoading"
import { useAppDispatch, useAppSelector } from "../reducers/hooks"
import {
  hideGlobalSearch,
  isGlobalSearchVisible,
  showGlobalSearch,
} from "../reducers/overlaysSlice"
import { isMobile } from "../utils/detectDevice"
import { mobileRoutes, setMobileRoute } from "../routes/routes"
import { markAllChatsAsRead } from "../reducers/chatsSliceThunks"
import DarkModeToggle from "./DarkModeToggle"
import { getWhatsappStatus } from "../reducers/rosterThunks"
import { captureError } from "../ErrorHandlers"
import WhatsappAccountStatusDialog from "./WhatsappAccountStatusDialog"

type ChatListHeaderProps = {
  onOpenChatInfo: () => void
  onActiveChatChange: (chatJid: string) => void
}

const ChatListHeader = ({
  onOpenChatInfo,
  onActiveChatChange,
}: ChatListHeaderProps) => {
  const { client, myJid } = useContext(XmppContext)
  const dispatch = useAppDispatch()

  const [whatsappStatus, setWhatsappStatus] = useState<string | undefined>(
    undefined,
  )

  const [accountStatusDialogOpen, setAccountStatusDialogOpen] =
    useState<boolean>(false)

  useEffect(() => {
    dispatch(getWhatsappStatus(client))
      .then(setWhatsappStatus)
      .catch((e) => {
        captureError(e, { origin: "Other" })
      })
  }, [client, dispatch])

  const searchVisible = useAppSelector(isGlobalSearchVisible)

  const handleShowSearch = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      // @ts-ignore
      e.currentTarget?.blur?.()
      dispatch(showGlobalSearch())
      if (isMobile) {
        setMobileRoute(mobileRoutes.SEARCH)
      }
    },
    [dispatch],
  )

  const handleCloseSearch = useCallback(() => {
    if (isMobile) {
      setMobileRoute(mobileRoutes.CHAT_LIST)
    }
    dispatch(hideGlobalSearch())
  }, [dispatch])

  const onMarkAllChatsReadClick = useCallback(() => {
    dispatch(markAllChatsAsRead(client))
  }, [client, dispatch])

  const onSetWhatsAppAccountStatus = () => {
    setAccountStatusDialogOpen(true)
  }

  const closeAccountStatusDialog = useCallback(() => {
    setAccountStatusDialogOpen(false)
  }, [])

  const onSaveAccountStatus = useCallback(
    async (status: string) => {
      closeAccountStatusDialog()
      setWhatsappStatus(status)
    },
    [closeAccountStatusDialog],
  )

  return (
    <>
      <WhatsappAccountStatusDialog
        isOpen={accountStatusDialogOpen}
        status={whatsappStatus}
        onSaveSuccess={onSaveAccountStatus}
        onClose={closeAccountStatusDialog}
      />
      <div className={css.chatListHeader}>
        <div className="flex flex-row space-x-2 align-center">
          <Button
            icon={
              <span
                className={classNames(css.status, {
                  [css.offline]: client.status !== "online",
                })}
              ></span>
            }
            minimal
            onClick={onOpenChatInfo}
          >
            <b>{myJid.getLocal()}</b>
          </Button>
          <RoomLoading />
        </div>
        <div className="flex flex-row justify-items-end gap-1">
          <DarkModeToggle />
          <OverlaysProvider>
            <Tooltip content="Search in messages" position="left">
              <Button
                data-testid="global-search-button"
                minimal
                icon={<Icon icon="search" size={18} />}
                onClick={handleShowSearch}
              />
            </Tooltip>
            <SearchPopover isOpen={searchVisible} onClose={handleCloseSearch} />
          </OverlaysProvider>
          <JoinChatPopover onActiveChatChange={onActiveChatChange} />
          <Popover
            interactionKind="click"
            content={
              <Menu>
                <MenuItem
                  text="Mark all as read"
                  onClick={onMarkAllChatsReadClick}
                  disabled={client.status !== "online"}
                />
                <MenuItem
                  text="Set account status"
                  onClick={onSetWhatsAppAccountStatus}
                  disabled={client.status !== "online"}
                />
              </Menu>
            }
            placement="bottom"
            renderTarget={({ isOpen, ...targetProps }) => (
              <Button
                {...targetProps}
                icon={
                  <img
                    src="/navi-menu.svg"
                    alt="Menu icon"
                    width={18}
                    height={18}
                  />
                }
                minimal
                small
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default ChatListHeader
